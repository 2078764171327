import React, { useState, useEffect } from 'react';
import './Confirm.css';
import './Page-form-inner.css';


function Confirm(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.email !== undefined) {
            setEmail(props.email);
        }
    }, [props.email])

    useEffect(() => {
        if (props.isLoading !== undefined) {
            setIsLoading(props.isLoading);
        }
    }, [props.isLoading])

    function downloadPressed() {
        window.location.href = "https://apps.apple.com/rs/app/eigen-fit/id1498333582"
    }

	return (
		<div className="confirm page-form-inner">
			<div hidden={isLoading} className="page-form-inner-question-container">
                {/*<img className="confirm-image" src={`${process.env.PUBLIC_URL}/assets/images/icons/confirmed.png`}/>*/}
                <div className="confirm-app-icon">
                    <p>eigen</p>
                </div>
                <h2>{ props.hasTrainer ? `Chat with ${ props.selectedTrainer ? props.selectedTrainer.displayName : "Your Coach" } on the app!` : "Your 14-day program is waiting!" }</h2>
                <p>{    props.hasTrainer ?
                        `${props.selectedTrainer ? props.selectedTrainer.displayName : "Your Coach"} will reach out in the next 24 hours on the Eigen Fitness app to say hello and introduce themselves. Download the app so you don't miss a message!`
                        :
                        "Congratulations on taking your first step towards your fitness goals! Download the Eigen Fitness app to start your first workout."
                    }</p>
                <div onClick={ () => downloadPressed() } className="confirm-download-button">
                    <p>Get the app</p>
                </div>
            </div>
            <div hidden={!isLoading} className="confirm-loading-container">
                <div className="page-form-inner-loading-container">
                    <svg className="gegga">
                        <defs>
                            <filter id="gegga">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="7" result="blur"></feGaussianBlur>
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 20 -10" result="inreGegga"></feColorMatrix>
                                <feComposite in="SourceGraphic" in2="inreGegga" operator="atop"></feComposite>
                            </filter>
                        </defs>
                    </svg>
                    <svg className="snurra" width="200" height="200" viewBox="0 0 200 200">
                        <defs>
                            <linearGradient id="linjärGradient">
                                <stop className="stopp1" offset="0"></stop>
                                <stop className="stopp2" offset="1"></stop>
                            </linearGradient>
                            <linearGradient y2="160" x2="160" y1="40" x1="40" gradientUnits="userSpaceOnUse" id="gradient" href="#linjärGradient"></linearGradient>
                        </defs>
                        <path className="halvan" d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776 -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64 0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736 -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64 64,-64"></path>
                        <circle className="strecken" cx="100" cy="100" r="64"></circle>
                    </svg>
                    <p>Adding your program to Eigen Fitness...</p>
                </div>
            </div>
		</div>
	)
}

export default Confirm;