import React, { useState, useEffect } from 'react';
import './Style.css';
import './Page-form-inner.css';

import StyleCard from '../components-form/StyleCard';


function Style(props: any) {

    const cardOptions: {title: string, id: any, img?: string}[] = [
        {
            title: "General Health and Wellness",
            id: "general",
            img: "wellness"
        },
        {
            title: "Bodybuilding",
            id: "bodybuilding",
            img: "bodybuilding"
        },
        {
            title: "Powerlifting",
            id: "powerlifting",
            img: "powerlifting"
        },
        {
            title: "Crossfit",
            id: "crossfit",
            img: "crossfit"
        },
        {
            title: "Functional Fitness",
            id: "functional-fitness",
            img: "functional"
        },
        {
            title: "Olympic weightlifting",
            id: "olympic-lifting",
            img: "olympic"
        },
        {
            title: "General Sport Training",
            id: "general-sport",
            img: "sport"
        },
        {
            title: "Running Improvement",
            id: "running-improvement",
            img: "running"
        },
        {
            title: "Cycling Improvement",
            id: "cycling-improvement",
            img: "cycling"
        }
    ]

    const [initSet, setInitSet] = useState(false);

    const [selectedCardOption, setSelectedCardOption] = useState<{title: string, id: any} | undefined>(undefined);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.styleSelected !== undefined) {
            props.styleSelected(selectedCardOption);
        }
    }, [selectedCardOption])

    function cardSelcted(withObj: {title: string, id: any}) {
        setSelectedCardOption(withObj);
        console.log("cardSelcted", withObj)
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

	return (
		<div className="style page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>Which training style best describes your goals?</h2>
            </div>
            <div className="page-form-inner-input-container">
                <div className="style-cards-wrapper">
                    {
                        cardOptions.map((item: {title: string, id: any, img?: string}, index: number) => (
                            <StyleCard 
                                title={item.title}
                                id={item.id}
                                index={index}
                                imgName={item.img === undefined ? '' : item.img}
                                isSelected={selectedCardOption !== undefined && selectedCardOption.id === item.id}
                                cardSelected={(t: string, i: string) => cardSelcted({title: t, id: i})}/>
                        ))
                    }
                </div>
            </div>
        </div>
	)
}

export default Style;