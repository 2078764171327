
import React, { useState, useEffect } from 'react';
import './Gender.css';
import './Page-form-inner.css';
import GenderCard from '../components-form/GenderCard';
import '@material-ui/core';

function TrainerIntensity(props: any) {
    const [initSet, setInitSet] = useState(false);
    const [cardOptions, setCardOptions] = useState<{
        title: string;
        id: any;
        active: boolean;
    }[]>([
        {
            title: 'Insanely Intense',
            id: 'intense',
            active: false,
        },
        {
            title: 'Push Yourself',
            id: 'push',
            active: false,
        },
        {
            title: 'Above Comfortable',
            id: 'aboveComfortable',
            active: false,
        },
        {
            title: 'Comfortable',
            id: 'comfortable',
            active: false,
        },
        {
            title: 'Chill and Relaxed',
            id: 'chillAndRelaxed',
            active: false,
        },
    ]);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() { }

    useEffect(() => { }, [cardOptions]);

    function cardSelected(withObj: { title: string; id: any; active: boolean }) {
        setCardOptions(
            cardOptions.map((item: any, index: number) => {
                if (item.id === withObj.id) {
                    return { ...item, active: withObj.active };
                } else {
                    return item;
                }
            })
        );

        props.setTrainerIntensity(withObj.id);

        nextButtonPressed();
    }

    function nextButtonPressed() {
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

    return (
        <div className="weekdays page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>Trainer details</h2>
                <p>There is no wrong answer to how hard you want to train - we'll make it work with your goal</p>
            </div>
            <div className="page-form-inner-input-container">
                <div className="gender-cards-wrapper">
                    {cardOptions.map(
                        (item: { title: string; id: any; active: boolean }, index: number) => (
                            <GenderCard
                                key={item.id}
                                title={item.title}
                                id={item.id}
                                index={index}
                                isSelected={item.active}
                                cardSelected={(t: string, i: string, a: boolean) =>
                                    cardSelected({ title: t, id: i, active: a })
                                }
                            />
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default TrainerIntensity;
