//import { Mixpanel } from '@ionic-native/mixpanel';
import mixpanel from 'mixpanel-browser';

mixpanel.init('ac1308a9ab435f7acd262adb960cce0c');

export default mixpanel;

// Mixpanel.init('ac1308a9ab435f7acd262adb960cce0c')
// 	.then((resp: any) => {
// 		console.log(`MIXPANEL INIT SUCCESS`, resp);
// 	})
// 	.catch((e: any) => {
// 		console.log(`MIXPANEL INIT FAIL`, e);
// 	});
// export default Mixpanel;
