import React, { useState, useEffect } from 'react';
import './StyleCard.css';


import '@material-ui/core';
import Check from '@material-ui/icons/Check';

function StyleCard(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        console.log("props.isSelected", props.isSelected);
        if (props.isSelected !== undefined) {
            setIsSelected(props.isSelected);
        }
    }, [props.isSelected])

    function cardSelcted() {
        if (props.cardSelected !== undefined) {
            props.cardSelected(props.title, props.id);
        }
    }

	return (
		<div onClick={() => cardSelcted()} className={`style-card ${isSelected ? 'style-card-selected' : ''}`}>
            <div hidden={!isSelected} className="style-card-check-container">
                <Check className="style-card-check"/>
            </div>
            <div className="style-card-inner">
    			<div className="style-card-icon-container">
                    <div className="style-card-icon-bg">
                        <img className="style-image" src={`${process.env.PUBLIC_URL}/assets/images/body/style/${props.imgName}.png`}/>
                    </div>
                </div>
                <div className="style-card-title-container">
                    <h3>{props.title === undefined ? 'Undefined card' : props.title}</h3>
                </div>
            </div>
		</div>
	)
}

export default StyleCard;