import React, { useState } from 'react';
import './MembershipElement.css';


function MembershipElement(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    function membershipPressed() {
        props.membershipPressed();
    }

    function liteMembershipPressed() {
        props.liteMembershipPressed();
    }

	return (
		<div className="memberhip-element">
			<div className="memberhip-element-content">
                <div className="memberhip-element-header-container">
                    <div className={ `memberhip-element-header-blurb-row memberhip-element-header-blurb-row-${ props.numNodes > 0 ? 'nodes' : 'only-training' }` }>
                        <p>{ props.numNodes > 0 ? `TRAIN WITH ${props.numNodes} NODES` : "TRAINING ONLY" }</p>
                    </div>
                    <div className="memberhip-element-header-title-row">
                        <div className="memberhip-element-header-title-container">
                            <h2>{ props.membershipTitle }</h2>
                        </div>
                        <div className="memberhip-element-header-price-container">
                            <h2>{props.membershipCurrency}{ props.membershipPrice }</h2>
                            <h3>/ mo</h3>
                        </div>
                    </div>
                </div>
                <div className="memberhip-element-body-container">
                    <p>{ props.membershipInfo }</p>
                </div>
                <div className="memberhip-element-cta-wrapper">
                    {
                        props.isAvailable ?
                        <div onClick={ () => membershipPressed() } className="memberhip-element-button-container">
                            <div className="memberhip-element-button-text-container">
                                <p>Start my 14-day free trail</p>
                            </div>
                            <div className="memberhip-element-button-icon-container">
                                <img className="memberhip-element-button-icon" src={`${process.env.PUBLIC_URL}/assets/images/icons/arrow-white.png`}/>
                            </div>
                        </div>
                        :
                        <div className="memberhip-element-sold-out-container">
                            <div className="memberhip-element-sold-out-text-container">
                                <h4>Sold out</h4>
                            </div>
                            <div className="memberhip-element-sold-out-next-steps-text-container">
                                <p>Still want to train with Eigen Fitness? Start with a free trial of a <span onClick={ () => liteMembershipPressed() } className="memberhip-element-sold-out-next-steps-text-button">Lite Membership</span> and upgrade to a Plus Membership when Nodes are back in stock.</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
		</div>
	)
}

export default MembershipElement;