import React, { useState } from 'react';
import './Intro.css';
import './Page-form-inner.css';


function Intro(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="intro">
			<div className="intro-page-left-container">
                <div className="intro-page-left-image-wrapper">
                    <img className="intro-page-left-image" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/onboarding-hero.png`}/>
                </div>
            </div>
            <div className="intro-page-right-container">
                <div className="intro-page-right-header-container">
                    <h2>The best version of you is within reach.</h2>
                    <p>Match. Program. Interact. Results.<br/>Click below to start the 4 steps towards achieving your goals. </p>
                </div>
                <div className="intro-page-right-button-container">
                    <div onClick={ () => props.nextPressed() } className="intro-page-right-button">
                        <p>Find my Coach</p>
                        <div className="intro-page-right-button-icon-container">
                            <img className="intro-page-right-button-icon" src={`${process.env.PUBLIC_URL}/assets/images/icons/btn-arrow.png`}/>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default Intro;