import React, { useState, useEffect } from 'react';
import './Gender.css';
import './Page-form-inner.css';
import GenderCard from '../components-form/GenderCard';


function TrainingIntend(props: any) {
    const [initSet, setInitSet] = useState(false);
    const [cardOptions, setCardOptions] = useState<{
        title: string;
        id: any;
        active: boolean;
    }[]>([
        {
            title: 'I would like to learn more',
            id: 'yes',
            active: false,
        },
        {
            title: 'I already have a trainer',
            id: 'already',
            active: false,
        },
        {
            title: 'Not right now',
            id: 'no',
            active: false,
        },
    ]);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() { }

    useEffect(() => { }, [cardOptions]);

    function cardSelected(withObj: {
        title: string;
        id: any;
        active: boolean;
    }) {
        setCardOptions(
            cardOptions.map((item: any, index: number) => {
                if (item.id === withObj.id) {
                    return { ...item, active: withObj.active };
                } else {
                    return item;
                }
            })
        );

        props.setTrainingIntend(withObj.id);

        if (withObj.id === 'already' || withObj.id === 'no') {

            nextButtonPressedSkip();
        } else if (withObj.id === 'yes') {
            nextButtonPressed();
        }
    }

    function nextButtonPressed() {
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

    function nextButtonPressedSkip() {
        if (props.nextPagePressedSkip !== undefined) {
            props.nextPagePressedSkip();
        }
    }

    return (
        <div className="weekdays page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>Get started with your Coach</h2>
                <p>
                    Virtual personal training is the simplest, most cost-effective way of maximizing your results.
                </p>
            </div>
            <div className="page-form-inner-input-container">
                <div className="gender-cards-wrapper">
                    {cardOptions.map((item: any, index: number) => (
                        <GenderCard
                            key={item.id}
                            title={item.title}
                            id={item.id}
                            index={index}
                            isSelected={item.active}
                            cardSelected={(t: string, i: string, a: boolean) =>
                                cardSelected({ title: t, id: i, active: a })
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TrainingIntend;
