import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import './Name.css';
import './Page-form-inner.css';


import '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

function Name(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [okayToProceed, setOkayToProceed] = useState(false);
    const [inputString, setInputString] = useState('');
    const [inputFocused, setInputFocused] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        attemptApproval();
        if (props.nameUpdated !== undefined) {
            props.nameUpdated(inputString);
        }
    }, [inputString])

    function initialize() {
        
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            nextPagePressed();
        }
    }

    function inputChanged(toString: any, forInput: string) {
        setInputString(toString);
        attemptApproval();
    }

    function attemptApproval() {
        const regex = /^([\w]{2,})+\s+([\w\s]{2,})+$/i; ///^[a-zA-Z0-9]{4,}$/;
        let isPassed = regex.test(inputString);
        setOkayToProceed(isPassed)
    }

    function nextPagePressed() {
        if (okayToProceed === true && props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

	return (
		<div className="name page-form-inner">
			<div className="page-form-inner-question-container">
                <h2>What's your full name?</h2>
                <p>What should your trainer call you?</p>
            </div>
            <div className="page-form-inner-input-container">
                <div className={`page-form-inner-input-text ${inputFocused ? 'page-form-inner-input-text-focused' : ''}`}>
                    <div className="page-form-inner-input-text-ion-wrapper">
                        <IonInput value={inputString} 
                            autofocus={true}
                            placeholder="My first and last name is..."
                            inputmode="text"
                            clearOnEdit={true}
                            type="text"
                            onKeyPress={e => inputUpdated(e.key)}
                            onIonChange={e => inputChanged(e.detail.value!, 'client-name')}
                            onFocus={(e) => setInputFocused(true)}
                            onBlur={(e) => setInputFocused(false)}/>
                    </div>
                    <div onClick={() => nextPagePressed()} className={`page-form-inner-input-text-button ${okayToProceed ? '' : 'page-form-inner-input-text-button-unselected'}`}>
                        <ArrowForward className="page-form-inner-input-text-button-icon"/>
                    </div>
                    
                </div>
            </div>
            <div className="page-form-inner-input-enter-icon-container">
                <div className={`page-form-inner-input-enter-icon ${inputFocused && okayToProceed ? '' :'page-form-inner-input-enter-icon-hide'}`}>
                    <p>Enter</p>
                </div>
            </div>
		</div>
	)
}

export default Name;