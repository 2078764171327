import React, { useState, useEffect } from 'react';
import './Style.css';
import './Page-form-inner.css';

import GenderCard from '../components-form/GenderCard';


function Drive(props: any) {

    const cardOptions: { title: string, id: any, img?: string }[] = [
        {
            title: "Loose Weight",
            id: "looseWeight",
            img: "bodybuilding"
        },
        {
            title: "Get Toned",
            id: "toned",
            img: "bodybuilding"
        },
        {
            title: "Increase Muscle Mass",
            id: "muscleMass",
            img: "bodybuilding"
        },
        {
            title: "Improve Health",
            id: "health",
            img: "bodybuilding"
        },
        {
            title: "Improve Sports Performance",
            id: "completition",
            img: "bodybuilding"
        },
        {
            title: "Train for Competition",
            id: "competition",
            img: "bodybuilding"
        },
        {
            title: "I'm not sure...",
            id: "notSure",
            img: "bodybuilding"
        },
    ]

    const [initSet, setInitSet] = useState(false);

    const [selectedCardOption, setSelectedCardOption] = useState<{ title: string, id: any } | undefined>(undefined);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {

    }

    useEffect(() => {
        if (props.driveSelected !== undefined) {
            props.driveSelected(selectedCardOption);
        }
    }, [selectedCardOption])

    function cardSelcted(withObj: { title: string, id: any }) {
        setSelectedCardOption(withObj);
        console.log("cardSelcted", withObj)
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

    return (
        <div className="style page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>What's your drive?</h2>
                <p>We all have unique goals when it comes to personal training. What is yours?</p>
            </div>
            <div className="page-form-inner-input-container">
                <div className="style-cards-wrapper">
                    {
                        cardOptions.map((item: { title: string, id: any, img?: string }, index: number) => (
                            <GenderCard
                                key={item.id}
                                title={item.title}
                                id={item.id}
                                index={index}
                                isSelected={ selectedCardOption !== undefined && selectedCardOption.id === item.id }
                                cardSelected={ (t: string, i: string, a: boolean) => cardSelcted({ title: t, id: i }) }
                            />
                           
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Drive;