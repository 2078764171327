import React, { useState, useEffect } from 'react';
import './CoachDisplay.css';
import './Page-form-inner.css';

import TrainerOnboarding_t from '../Interfaces/TrainerOnboarding_t';

import CoachCard from '../components-form/CoachCard';


function CoachDisplay(props: any) {


    const [initSet, setInitSet] = useState(false);
    const [selectedCardOption, setSelectedCardOption] = useState<{ title: string, id: any } | undefined>(undefined);
    const [coachOptions, setCoachOptions] = useState<TrainerOnboarding_t[]>([]);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() { }

    useEffect(() => {
        if (selectedCardOption !== undefined) {
            props.setCoach(selectedCardOption.id);
        }
    }, [selectedCardOption])

    useEffect(() => {
        if (!props.trainerOptions) return;
        setCoachOptions(props.trainerOptions);
    }, [props.trainerOptions])

    function cardSelcted(withObj: { title: string, id: any }) {
        setSelectedCardOption(withObj);
        console.log("cardSelcted", withObj)
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

    return (
        <div className="page-form-inner">
            <div className="page-form-inner-question-container page-form-inner-question-container-sm-padding">
                <h2>Get started with your new Coach</h2>
                <p>We curated a list of the 3 most relevant trainers based on your input. Get started with one of them below.</p>
            </div>
            <div className="coach-cards-wrapper">
                <div className="coach-cards-container">
                    {
                        coachOptions.map((item: TrainerOnboarding_t, index: number) => (
                            <CoachCard
                                selectedTrainer={ item }
                                cardSelected={ (t: TrainerOnboarding_t) => props.updateSelectedTrainer(t) } />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default CoachDisplay;