import React, { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Mixpanel from '../Mixpanel';

import axios from 'axios';

import './Payment.css';
import './Page-form-inner.css';

import PaymentForm from '../components-form/PaymentForm';

const stripePromise = loadStripe("pk_live_w4TU7UCB6pNG9z5tTfvVnNS800mKJLle5i");


function Payment(props: any) {

    const [clientSecret, setClientSecret] = useState("pi_3MNOLsDyMxufd20G1QTSMt0K_secret_1pS3mnGiiOAKClteUlJ8oo6jC");

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [initSet, setInitSet] = useState(false);

    const [showPaymentInfo, setShowPaymentInfo] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.email !== undefined) {
            setEmail(props.email);
        }
    }, [props.email])

    useEffect(() => {
        if (props.name !== undefined) {
            setName(props.name);
        }
    }, [props.name])


    function createPaymentIntent() {


        // 1. Get pricing parameters
        axios.get(`https://eigenfitness.com/proliftapi/config-price`,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            let resp = response.data;
            console.log("config-price resp:", resp)
            createClientSecret(resp.unitAmount === undefined ? (resp.amount === undefined ? 0 : resp.amount) : resp.unitAmount, resp.currency === undefined ? 'usd' : resp.currency);
        })
        .catch(e => {
            console.log("ERROR from config-price:", e);
        })
    }

    function createClientSecret(amount: number, currency: string) {
        // 2. Get client secret by creating payment intent with pricing parameters

        let sendObj = {
            amount: amount,
            currency: currency,
            email: email,
            name: name
        }

        console.log("sending to create-payment-intent: ", sendObj)

        axios.post(`https://eigenfitness.com/proliftapi/create-payment-intent`,
        sendObj,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            //console.log(response);
            let resp = response.data;
            
            if (resp.clientSecret !== undefined) {
                setClientSecret(resp.clientSecret);
            }
        })
        .catch(e => {
            console.log("ERROR from create-payment-intent:", e);
        })


    }

    // useEffect(() => {
    //     // Create PaymentIntent as soon as the page loads
    //     fetch("/create-payment-intent", {
    //         method: "POST",
    //         headers: { "Content-Type": "application/json" },
    //         body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    //     })
    //         .then((res) => res.json())
    //         .then((data) => setClientSecret(data.clientSecret));
    // }, []);

    function confirmPurchaseIntent() {
        //props.paymentSuccess();
        Mixpanel.track("Confirm purchase intent", {"service": "Prolift"});

        setShowPaymentInfo(true); 
        createPaymentIntent();
    }
    
    const appearance: any = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    };

	return (
        <div className="payment page-form-inner">
            <div className="payment-card">
                <div className="payment-summary-container">
                    <div className="payment-summary-header">
                        <h4>Finally reach your fitness goals. Results backed by AI.</h4>
                        <p>Now to January 31st, get <span>75% off</span> your custom, data driven workout program to hit your goals faster. </p>
                    </div>
                    <div className="payment-summary-order-summary">
                        <div className="payment-summary-order-header">
                            <h4>Order Summary</h4>
                        </div>
                        <div className="payment-summary-order-body">
                            <div className="payment-summary-order-body-row">
                                <div className="payment-summary-order-body-row-desc">
                                    <p>Customized Workout Program</p>
                                </div>
                                <div className="payment-summary-order-body-row-amount">
                                    <h4>$19.99</h4>
                                </div>
                            </div>
                            <div className="payment-summary-order-body-row payment-summary-order-body-row-discount">
                                <div className="payment-summary-order-body-row-desc">
                                    <p>Discount Applied</p>
                                    <div className="payment-summary-order-body-row-discount-code-container">
                                        <p>NewYear75</p>
                                    </div>
                                </div>
                                <div className="payment-summary-order-body-row-amount">
                                    <h4>-$15.00</h4>
                                </div>
                            </div>
                        </div>
                        <div className="payment-summary-order-final">
                            <div className="payment-summary-order-body-row-desc">
                                <p>You pay</p>
                            </div>
                            <div className="payment-summary-order-body-row-amount">
                                <h4>$4.99</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={showPaymentInfo} onClick={() => confirmPurchaseIntent() } className="payment-summary-button">
                    <p>Get My Program</p>
                </div>
                <div hidden={!showPaymentInfo} className="payment-info-container">
                    { clientSecret && stripePromise && (
                        <Elements stripe={stripePromise} options={{ clientSecret, }}>
                            <PaymentForm 
                                clientSecret={clientSecret}
                                paymentSuccess={() => props.paymentSuccess()}/>
                        </Elements>
                    )}
                </div>
            </div>
            
            
        </div>
	)
}

export default Payment;