import React, { useState } from 'react';
import './CoachCard.css';


function CoachCard(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="coach-card">
			<div className="coach-card-container">
                <div className="coach-card-image-container">
                    <img className="coach-card-image" src={`${process.env.PUBLIC_URL}/assets/images/coaches/${props.selectedTrainer.imageUrl}`}/>
                </div>
                <div className="coach-card-content-container">
                    <div className="coach-card-content-header-container">
                        <p>{ props.selectedTrainer.title }</p>
                        <h2>{ props.selectedTrainer.displayName }</h2>
                    </div>
                    <div className="coach-card-content-body-container">
                        <p>{ props.selectedTrainer.blurb }</p>
                    </div>
                    <div onClick={ () => props.cardSelected(props.selectedTrainer) } className={`coach-card-content-button-container ${props.selectedTrainer.isAvailable ? 'coach-card-content-button-container-active' : 'coach-card-content-button-container-disabled'}`}>
                        <p>{ props.selectedTrainer.isAvailable ? `Train with ${props.selectedTrainer.firstName}` : 'All Booked Up' }</p>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default CoachCard;