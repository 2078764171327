import React, { useState, useEffect } from 'react';
import './WeekdayCard.css';


import '@material-ui/core';
import Check from '@material-ui/icons/Check';

function WeekdayCard(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        console.log("props.isSelected", props.isSelected);
        if (props.isSelected !== undefined) {
            setIsSelected(props.isSelected);
        }
    }, [props.isSelected])

    function cardSelcted() {
        if (props.cardSelected !== undefined) {
            props.cardSelected(props.title, props.id, !isSelected);
        }
    }

	return (
		<div onClick={() => cardSelcted()} className={`weekday-card ${isSelected ? 'weekday-card-selected' : ''}`}>
            <div hidden={!isSelected} className="weekday-card-check-container">
                <Check className="weekday-card-check"/>
            </div>
            <div className="weekday-card-inner">
                <div className="weekday-card-title-container">
                    <h3>{props.title === undefined ? 'Undefined card' : props.title}</h3>
                </div>
            </div>
		</div>
	)
}

export default WeekdayCard;