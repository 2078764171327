import React, { useState, useEffect } from 'react';
import './Experience.css';
import './Page-form-inner.css';

import ExperienceCard from '../components-form/ExperienceCard';


function Experience(props: any) {

    const cardOptions: {title: string, id: any, img?: string}[] = [
        {
            title: "I'm just starting out now",
            id: "beginner",
            img: "starting"
        },
        {
            title: "I've been lifting for a few months",
            id: "intermediate",
            img: "intermediate"
        },
        {
            title: "I'm fairly experienced in the gym",
            id: "advanced",
            img: "advanced"
        },
        {
            title: "I know more than you",
            id: "expert",
            img: "expert"
        }
    ]


    let styleVerbs: any = {
        "general": "working out",
        "bodybuilding": "bodybuilding",
        "powerlifting": "powerlifting",
        "crossfit": "doing crossfit",
        "functional-fitness": "lifting",
        "olympic-lifting": "olympic lifitng",
        "general-sport": "training",
        "running-improvement": "running",
        "cycling-improvement": "cycling"
    }


    const [initSet, setInitSet] = useState(false);
    const [selectedCardOption, setSelectedCardOption] = useState<{title: string, id: any} | undefined>(undefined);
    const [styleVerb, setStyleVerb] = useState("lifting");

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (selectedCardOption !== undefined) {
            props.experienceUpdated(selectedCardOption.id);
        }
    }, [selectedCardOption])

    useEffect(() => {
        if (props.style !== undefined && props.style.id !== undefined) {
            if (styleVerbs[props.style.id] === undefined) {
                setStyleVerb("lifting");
            } else {
                setStyleVerb(styleVerbs[props.style.id]);
            }
        }
    }, [props.style])

    function cardSelcted(withObj: {title: string, id: any}) {
        setSelectedCardOption(withObj);
        console.log("cardSelcted", withObj)
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

	return (
		<div className="experience page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>How long have you been {styleVerb}?</h2>
            </div>
            <div className="page-form-inner-input-container">
                <div className="experience-cards-wrapper">
                    {
                        cardOptions.map((item: {title: string, id: any, img?: string}, index: number) => (
                            <ExperienceCard 
                                title={item.title}
                                id={item.id}
                                index={index}
                                imgName={item.img}
                                isSelected={selectedCardOption !== undefined && selectedCardOption.id === item.id}
                                cardSelected={(t: string, i: string) => cardSelcted({title: t, id: i})}/>
                        ))
                    }
                </div>
            </div>
        </div>
	)
}

export default Experience;