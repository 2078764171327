import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import './Age.css';
import './Page-form-inner.css';


import '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

function Age(props: any) {
    const [initSet, setInitSet] = useState(false);
    const [okayToProceed, setOkayToProceed] = useState(false);
    const [inputString, setInputString] = useState('');
    const [inputFocused, setInputFocused] = useState(false);

    const [name, setName] = useState('');
    

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        attemptApproval();
    }, [inputString])

    useEffect(() => {
        if (props.name !== undefined) {
            setName(props.name);
        }
    }, [props.name])

    function initialize() {
        
    }

    function inputUpdated(e: any) {
        if (e === "Enter") {
            nextPagePressed();
        }
    }

    function inputChanged(toString: any, forInput: string) {

        console.log("inputChanged");

        setInputString(toString);
        attemptApproval();
        

        
        
    }

    function attemptApproval() {

        let gucci = !isNaN(Number(inputString)) && Number(inputString) > 15;

        setOkayToProceed(gucci);
        if (gucci) {
            props.ageUpdated(inputString);
        }
    }

    function nextPagePressed() {
        if (okayToProceed === true && props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

    return (
        <div className="age page-form-inner">
            <div className="page-form-inner-question-container">
                <h4>{name === undefined || name === "" ? "" : `Nice to meet you, ${name}.`}</h4>
                <h2>How old are you?</h2>
                <p>This helps us create a program tailored to your body’s needs.</p>
            </div>
            <div className="page-form-inner-input-container">
                <div className={`page-form-inner-input-text ${inputFocused ? 'page-form-inner-input-text-focused' : ''}`}>
                    <div className="page-form-inner-input-text-ion-wrapper">
                        <IonInput value={inputString} 
                            autofocus={true}
                            placeholder="I am ..... years old"
                            inputmode="numeric"
                            clearOnEdit={true}
                            type="number"
                            onKeyPress={e => inputUpdated(e.key)}
                            onIonChange={e => inputChanged(e.detail.value!, 'client-name')}
                            onFocus={(e) => setInputFocused(true)}
                            onBlur={(e) => setInputFocused(false)}/>
                    </div>
                    <div onClick={() => nextPagePressed()} className={`page-form-inner-input-text-button ${okayToProceed ? '' : 'page-form-inner-input-text-button-unselected'}`}>
                        <ArrowForward className="page-form-inner-input-text-button-icon"/>
                    </div>
                </div>
            </div>
            <div className="page-form-inner-input-enter-icon-container">
                <div className={`page-form-inner-input-enter-icon ${inputFocused && okayToProceed ? '' :'page-form-inner-input-enter-icon-hide'}`}>
                    <p>Enter</p>
                </div>
            </div>
        </div>
    )
}

export default Age;