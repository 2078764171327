import React, { useState, useEffect } from 'react';
import './Gender.css';
import './Page-form-inner.css';
import GenderCard from '../components-form/GenderCard';
import '@material-ui/core';

function WorkoutLocation(props: any) {
    const [initSet, setInitSet] = useState(false);
    const [cardOptions, setCardOptions] = useState<{
        title: string;
        id: any;
        active: boolean;
    }[]>([
        {
            title: 'At the gym',
            id: 'gym',
            active: false,
        },
        {
            title: 'At home',
            id: 'home',
            active: false,
        },
        {
            title: 'In a fitness studio',
            id: 'fitnessStudio',
            active: false,
        },
        {
            title: 'Outdoors',
            id: 'outdoors',
            active: false,
        },
        {
            title: 'Somewhere else',
            id: 'other',
            active: false,
        },
        {
            title: 'I am not sure',
            id: 'not sure',
            active: false,
        },
    ]);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() { }

    useEffect(() => { }, [cardOptions]);

    function cardSelected(withObj: { title: string; id: any; active: boolean }) {
        setCardOptions(
            cardOptions.map((item: any, index: number) => {
                if (item.id === withObj.id) {
                    return { ...item, active: withObj.active };
                } else {
                    return item;
                }
            })
        );

        props.setWorkOutLocation(withObj.id);

        nextButtonPressed();
    }

    function nextButtonPressed() {
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

    return (
        <div className="weekdays page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>Workout Location</h2>
                <p>Whether at the gym, at home, or in your back yard, we'll help you reach your most abitious goals.</p>
            </div>
            <div className="page-form-inner-input-container">
                <div className="gender-cards-wrapper">
                    {cardOptions.map(
                        (item: { title: string; id: any; active: boolean }, index: number) => (
                            <GenderCard
                                key={item.id}
                                title={item.title}
                                id={item.id}
                                index={index}
                                isSelected={item.active}
                                cardSelected={(t: string, i: string, a: boolean) =>
                                    cardSelected({ title: t, id: i, active: a })
                                }
                            />
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default WorkoutLocation;
