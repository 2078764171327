import React, { useState, useEffect } from 'react';
import './Weekdays.css';
import './Page-form-inner.css';


import WeekdayCard from '../components-form/WeekdayCard';

import '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

function Weekdays(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [cardOptions, setCardOptions] = useState<{title: string, id: any, active: boolean}[]>([
        {
            title: "Sunday",
            id: "0",
            active: false
        },
        {
            title: "Monday",
            id: "1",
            active: true
        },
        {
            title: "Tuesday",
            id: "2",
            active: true
        },
        {
            title: "Wednesday",
            id: "3",
            active: true
        },
        {
            title: "Thursday",
            id: "4",
            active: true
        },
        {
            title: "Friday",
            id: "5",
            active: true
        },
        {
            title: "Saturday",
            id: "6",
            active: false
        }
        
    ]);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {

        var activeList: string[] = [];
        for (var i = 0; i < cardOptions.length; i++) {
            if (cardOptions[i].active === true) {
                activeList.push(cardOptions[i].id);
            }
        }
        
        props.weekdaysUpdated(activeList);

    }, [cardOptions])

    function cardSelcted(withObj: {title: string, id: any, active: boolean}) {
       
        setCardOptions(cardOptions.map((item: any, index: number) => {
            if (item.id === withObj.id) {
                return {...item, active: withObj.active}
            } else {
                return item;
            }
        }));
    }

    function nextButtonPressed() {
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

    function getNumActiveDays() {
        var sum = 0;
        for (var i = 0; i < cardOptions.length; i++) {
            if (cardOptions[i].active === true) {
                sum += 1;
            }
        }

        return sum;
    }

	return (
		<div className="weekdays page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>Which days of the week will you be free to exercise?</h2>
                <p>Your program will be a {getNumActiveDays()} day split.</p>
            </div>
            <div className="page-form-inner-input-container">
                <div className="experience-cards-wrapper">
                    {
                        cardOptions.map((item: {title: string, id: any, active: boolean}, index: number) => (
                            <WeekdayCard 
                                title={item.title}
                                id={item.id}
                                index={index}
                                isSelected={item.active}
                                cardSelected={(t: string, i: string, a: boolean) => cardSelcted({title: t, id: i, active: a})}/>
                        ))
                    }
                </div>
            </div>
            <div className="page-form-inner-confirm-button-container">
                <div onClick={() => nextButtonPressed()} className="page-form-inner-confirm-button">
                    <p>Coaching</p>
                    <ArrowForward className="page-form-inner-confirm-button-icon"/>
                </div>
            </div>
        </div>
	)
}

export default Weekdays;