import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import './PaymentForm.css'


function PaymentForm(props: any) {

    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState<any>(null);
    const [paymentMethod, setPaymentMethod] = useState<any>(null);

    const [initSet, setInitSet] = useState(false);

    const [clientSecret, setClientSecret] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (paymentMethod !== null && paymentMethod !== undefined) {
            handleConfirm({});
        }
    }, [paymentMethod])

    useEffect(() => {
        if (props.clientSecret !== undefined) {
            setClientSecret(props.clientSecret);
            console.log("Setting clientSecret to:", props.clientSecret);
        }
    }, [props.clientSecret])



    // useEffect(() => {
    //     if (!stripe) { return; }

    //     const clientSecret = new URLSearchParams(window.location.search).get(
    //       "payment_intent_client_secret"
    //     );

    //     if (!clientSecret) { return; }

    //     stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {

    //         if (paymentIntent === undefined) { return; }

    //         switch (paymentIntent.status) {
    //             case "succeeded":
    //                 setMessage("Payment succeeded!");
    //                 break;
    //             case "processing":
    //                 setMessage("Your payment is processing.");
    //                 break;
    //             case "requires_payment_method":
    //                 setMessage("Your payment was not successful, please try again.");
    //                 break;
    //             default:
    //                 setMessage("Something went wrong.");
    //                 break;
    //         }
    //     });
    // }, [stripe]);

    // const handleSubmit = async (e:any) => {
    //     e.preventDefault();

    //     if (!stripe || !elements) {
    //         // Stripe.js has not yet loaded.
    //         // Make sure to disable form submission until Stripe.js has loaded.
    //         return;
    //     }

    //     setIsLoading(true);

    //     //let adjustedElements: StripeElements = {...elements, update: update, fetchUpdates: fetchUpdates, create: update};
    //     let elements_stripe: StripeElements = elements;
    //     if (elements_stripe === null) { return; }

    //     const { error } = await stripe.confirmPayment({
    //         elements: elements_stripe,
    //         confirmParams: {
    //             // Make sure to change this to your payment completion page
    //             return_url: "http://localhost:3000",
    //         },
    //     });

    //     // This point will only be reached if there is an immediate error when
    //     // confirming the payment. Otherwise, your customer will be redirected to
    //     // your `return_url`. For some payment methods like iDEAL, your customer will
    //     // be redirected to an intermediate site first to authorize the payment, then
    //     // redirected to the `return_url`.
    //     if (error.type === "card_error" || error.type === "validation_error") {
    //         setMessage(error.message);
    //     } else {
    //         setMessage("An unexpected error occurred.");
    //     }

    //     setIsLoading(false);
    // };

    const handleSubmit = async (event: any) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const cardElement = elements.getElement(CardElement);

        try {
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement!,
            });

            console.log("result", result)

            if (result.error) {
                setError(result.error.message);
                setIsLoading(false);
            } else {
                setPaymentMethod(result.paymentMethod);
            }
        } catch (error) {
            setError((error as any).message);
            setIsLoading(false);
        }
    };

    const handleConfirm = async (event: any) => {
        // Block native form submission.
        //event.preventDefault();

        if (!stripe || !paymentMethod) {
            return;
        }

        try {
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod.id,
            });

            if (result.error) {
                setError(result.error.message);
                setIsLoading(false);
            } else {
                // The payment has been successfully processed!
                if (props.paymentSuccess !== undefined) {
                    props.paymentSuccess();
                    setIsLoading(false);
                }
                console.log(result);
            }
        } catch (error) {
            setError((error as any).message);
            setIsLoading(false);
        }
    };

    function update(b: any) {

    }

    function fetchUpdates() {
        return new Promise<{ error?: { message: string; status?: string | undefined; } | undefined; }>((resolve, reject) => {

        })
    }

   // Type '(b: any) => void' is not assignable to type '() => Promise<{ error?: { message: string; status?: string | undefined; } | undefined; }>'.


    const paymentElementOptions: {layout: "tabs" | "accordion" | "auto" | any | undefined} = {
        layout: "tabs"
    }

	return (
        <form onSubmit={handleSubmit}>
            <div className="payment-form-header">
                <h4>Payment Information</h4>
                <p>For full access to your custom program via email and on the Eigen Fitness app, enter your prefered method of payment below. We process payments with Stripe to ensure your payment information always stays secure.</p>
            </div>
            <CardElement />
            {error && <div className="payment-error">{error}</div>}
            <button hidden={isLoading} type="submit" disabled={!stripe}>
                Get My Program
            </button>
            <button hidden={!isLoading} className="">
                <div className="payment-loading-spinner"></div>
            </button>
        </form>
		// <form id="payment-form" onSubmit={handleSubmit}>
  //           <PaymentElement id="payment-element" options={paymentElementOptions} />
  //           <button disabled={isLoading || !stripe || !elements} id="submit">
  //               <span id="button-text">
  //                   {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
  //               </span>
  //           </button>
  //           {/* Show any error or success messages */}
  //           {message && <div id="payment-message">{message}</div>}
  //       </form>
	)
}

export default PaymentForm;