import React, { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentMethodMessagingElement, useStripe } from "@stripe/react-stripe-js";
import Mixpanel from '../Mixpanel';
import axios from 'axios';

import './Payment.css';
import './Page-form-inner.css';

import Membership_t from '../Interfaces/Membership_t';
import TrainerOnboarding_t from '../Interfaces/TrainerOnboarding_t';

import MembershipPaymentComponent from './MembershipPaymentComponent';
import PaymentForm from '../components-form/PaymentForm';

const stripePromise = loadStripe("pk_live_w4TU7UCB6pNG9z5tTfvVnNS800mKJLle5i"); // loadStripe("pk_test_2qNUagC744ZwKY0dHZiCYmgZ003xZDQj6s"); //sk_test_1WkkdTTJrvkHdJW3I82TRTLs00b5t0dBQb 

const DOMAIN = "https://eigenfitness.com/paymentprocessing"//"http://localhost:4242";




function Membership(props: any) {

    

    const [clientSecret, setClientSecret] = useState(undefined);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    //const [trainerName, setTrainerName] = useState("Sydney");
    //const [trainerImageURL, setTrainerImageURL] = useState(`profile-sydney.png`)
    const [selectedTrainer, setSelectedTrainer] = useState<TrainerOnboarding_t | undefined>(undefined);



    const [message, setMessage] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);


    const [initSet, setInitSet] = useState(false);

    const [showPaymentInfo, setShowPaymentInfo] = useState(false);

    const [selectedMembership, setSelectedMembership] = useState<Membership_t>({
            title: "Lite Membership",
            price: 99,
            price_key: 'lite',
            currency: 'US$',
            info: "Your Lite Membership comes with unlimited Coach messaging, 24h response time, and bi-weekly video checkins with your Coach.",
            isAvailable: true,
            numNodes: 0
        })
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (props.selectedMembership !== undefined) {
            setSelectedMembership(props.selectedMembership);
        }
    }, [props.selectedMembership])

    useEffect(() => {
        if (props.email !== undefined) {
            setEmail(props.email);
        }
    }, [props.email])

    useEffect(() => {
        if (props.name !== undefined) {
            setName(props.name);
        }
    }, [props.name])

    useEffect(() => {
        setSelectedTrainer(props.selectedTrainer);
    }, [props.selectedTrainer])

    function createCheckoutSession() {
        console.log("createCheckoutSession");

        let sendObj = {
            price_key: "lite"
        }

        axios.post(`${DOMAIN}/create-checkout-session`,
            sendObj,
            { headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',} })
        .then(response => {
            let resp = response.data;
            console.log("create-checkout-session:", resp.redirecturl);
            window.location.href = resp.redirecturl//resp.redirecturl;
            //createClientSecret(resp.unitAmount === undefined ? (resp.amount === undefined ? 0 : resp.amount) : resp.unitAmount, resp.currency === undefined ? 'usd' : resp.currency);
        })
        .catch(e => {
            console.log("ERROR from create-checkout-session:", e);
        })
    }

    function createCustomerTest() {
        console.log("createCustomerTest");

        let sendObj = {
            name: "Another Dude",
            email: "another_tester_dude_1@gmail.com"
        }

        axios.post(`${DOMAIN}/create-customer`,
            sendObj,
            { headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',} })
        .then(response => {
            let resp = response.data;
            console.log(resp);
            createSubscriptionTest(resp.id);
        })
        .catch(e => {
            console.log("ERROR from create-customer:", e);
        })
    }

    function createSubscriptionTest(clientID: string) {
        let sendObj = {
            customer_id: clientID,
            price_key: "lite"
        }

        console.log("createSubscriptionTest:", sendObj, clientID);

        axios.post(`${DOMAIN}/create-subscription`,
            sendObj,
            { headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',} })
        .then(response => {
            let resp = response.data;
            console.log(resp);
            if (resp.clientSecret !== undefined) {
                setClientSecret(resp.clientSecret);
            }
        })
        .catch(e => {
            console.log("ERROR from create-subscription:", e);
        })
    }

    function createPaymentIntent() {


        // 1. Get pricing parameters
        axios.get(`https://eigenfitness.com/proliftapi/config-price`,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            let resp = response.data;
            console.log("config-price resp:", resp)
            createClientSecret(resp.unitAmount === undefined ? (resp.amount === undefined ? 0 : resp.amount) : resp.unitAmount, resp.currency === undefined ? 'usd' : resp.currency);
        })
        .catch(e => {
            console.log("ERROR from config-price:", e);
        })
    }

    function createClientSecret(amount: number, currency: string) {
        // 2. Get client secret by creating payment intent with pricing parameters

        let sendObj = {
            amount: amount,
            currency: currency,
            email: email,
            name: name
        }

        console.log("sending to create-payment-intent: ", sendObj)

        axios.post(`https://eigenfitness.com/proliftapi/create-payment-intent`,
        sendObj,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            //console.log(response);
            let resp = response.data;
            
            if (resp.clientSecret !== undefined) {
                setClientSecret(resp.clientSecret);
            }
        })
        .catch(e => {
            console.log("ERROR from create-payment-intent:", e);
        })


    }

    // useEffect(() => {
    //     // Create PaymentIntent as soon as the page loads
    //     fetch("/create-payment-intent", {
    //         method: "POST",
    //         headers: { "Content-Type": "application/json" },
    //         body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    //     })
    //         .then((res) => res.json())
    //         .then((data) => setClientSecret(data.clientSecret));
    // }, []);

    function confirmPurchaseIntent() {
        //Mixpanel.track("Confirm purchase intent", {"service": "Prolift"});

        setShowPaymentInfo(true); 
        createCustomerTest();
        //createCheckoutSession();
    }

    const handleSuccess = () => {


        axios.get(`https://eigenfitness.com/email/welcome_client.php?trainer_name=${ props.selectedTrainer ? props.selectedTrainer.displayName : 'Your Coach' }&email=${ props.email }&client_name=${ props.name }`,
        { headers: {'Content-Type': 'application/json'} })
        .then(response => {
            console.log("Done sending welcome email", response);
        })
        .catch(e => {
            console.log("ERROR sending welcome email:", e);
        })

        props.paymentSuccess();
    }

    const handleError = (e: string) => {
        
    }

    const getEndingDate = (daysInTrial: number) => {
        let now = Date.now();
        let then = now + (daysInTrial * 24 * 3600 * 1000);
        let thenDate = new Date(then);

        let monthStrings = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${ monthStrings[thenDate.getMonth()] } ${ thenDate.getDate() }, ${ thenDate.getFullYear() }`

    }
    
    const appearance: any = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    };

	return (
        <div className="membership-select page-form-inner">
            <div className="membership-select-left-container membership-select-left-container-payment">
                <div className="membership-select-left-content">
                    <div className="membership-select-left-image-container">
                        <img className="membership-select-left-image" src={ `${process.env.PUBLIC_URL}/assets/images/coaches/${selectedTrainer ? selectedTrainer.imageUrl : 'profile-sydney.png'}` }/>
                    </div>
                    <div className="membership-select-left-info-container">
                        <h2>Train with { selectedTrainer ? selectedTrainer.firstName : 'Your Coach' }.</h2>
                        <p>{ selectedTrainer ? selectedTrainer.firstName : 'Your Coach' } is excited to schedule your first meeting together to dive into your training goals and build a program that will help you see results as fast as possible.</p>
                    </div>
                </div>
            </div>
            <div className="membership-select-right-container membership-select-right-container-payment">
                <div className="membership-card">
                    <div className="membership-card-header">
                        <div className="membership-card-header-back-button">
                            <p>Back to Memberships</p>
                        </div>
                        <h2>Your { selectedMembership.title }</h2>
                        <p>Try the Eigen Fitness { selectedMembership.title } risk-free for 14 days. If it’s not for you, cancel any time in the app or by contacting us.</p>
                    </div>
                    <div className="membership-card-order-summary-container">
                        <div className="membership-card-order-summary-header">
                            <div className="membership-card-order-summary-header-title">
                                <h3>Order Summary</h3>
                            </div>
                            <div className="membership-card-order-summary-header-value">
                                <p>Value (USD)</p>
                            </div>
                        </div>
                        <div className="membership-card-order-summary-content">
                            <div className="membership-card-order-summary-row-container">
                                <div className="membership-card-order-summary-row-header">
                                    <h3>{ selectedMembership.title }</h3>
                                    <p>14 day free trail applied. Ends { getEndingDate(14) }</p>
                                </div>
                                <div className="membership-card-order-summary-row-value">
                                    <h4>${ selectedMembership.price }.00</h4>
                                </div>
                            </div>
                        </div>
                        <div className="membership-card-order-summary-total-container">
                            <div className="membership-card-order-summary-total-row">
                                <h4>Today's Total</h4>
                                <h3>$0.00</h3>
                            </div>
                            <div className="membership-card-order-summary-total-info">
                                <p>If your { selectedMembership.title } is active at the end of your 14 day free trial, { selectedMembership.currency }{ selectedMembership.price } will be charged to the provided billing information on { getEndingDate(14) }.</p>
                            </div>
                        </div>
                    </div>
                    <div className="membership-card-billing-details-container">
                        <div className="membership-card-billing-details-header">
                            <h3>Billing Details</h3>
                            <p>Provide your preferred billing details below to book your first meeting with { selectedTrainer ? selectedTrainer.firstName : 'Your Coach' }. You will not be charged until the end of your 14 day free trial.</p>
                        </div>
                        <div className="payment-info-container">
                            <Elements stripe={stripePromise}>
                                <MembershipPaymentComponent 
                                    stripe={stripePromise}
                                    name={ props.name }
                                    email={ props.email }
                                    userUID={ props.userUID }
                                    trainerName={ selectedTrainer ? selectedTrainer.firstName : 'Your Coach' }
                                    updateIsLoading={ (l: boolean) => setIsLoading(l) }
                                    handleSuccess={ () => handleSuccess() }
                                    handleError={ (e: string) => handleError(e) }/>
                            </Elements>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default Membership;







const nothing = {
    stuff: `<div className="payment-card">
                <div className="payment-summary-container">
                    <div className="payment-summary-header">
                        <h4>Membership</h4>
                        <p>Now to January 31st, get <span>75% off</span> your custom, data driven workout program to hit your goals faster. </p>
                    </div>
                    <div className="payment-summary-order-summary">
                        <div className="payment-summary-order-header">
                            <h4>Order Summary</h4>
                        </div>
                        <div className="payment-summary-order-body">
                            <div className="payment-summary-order-body-row">
                                <div className="payment-summary-order-body-row-desc">
                                    <p>Customized Workout Program</p>
                                </div>
                                <div className="payment-summary-order-body-row-amount">
                                    <h4>$19.99</h4>
                                </div>
                            </div>
                            <div className="payment-summary-order-body-row payment-summary-order-body-row-discount">
                                <div className="payment-summary-order-body-row-desc">
                                    <p>Discount Applied</p>
                                    <div className="payment-summary-order-body-row-discount-code-container">
                                        <p>NewYear75</p>
                                    </div>
                                </div>
                                <div className="payment-summary-order-body-row-amount">
                                    <h4>-$15.00</h4>
                                </div>
                            </div>
                        </div>
                        <div className="payment-summary-order-final">
                            <div className="payment-summary-order-body-row-desc">
                                <p>You pay</p>
                            </div>
                            <div className="payment-summary-order-body-row-amount">
                                <h4>$4.99</h4>
                            </div>
                        </div>
                    </div>
                </div>
                
                {
                    isLoading ?
                    <div className="payment-info-loading-container">
                        <div className="payment-info-loading-container">
                            <div className="payment-loading-spinner"/>
                            <p>Loading...</p>
                        </div>
                    </div>
                    :
                    <div className="payment-info-container">
                        <Elements stripe={stripePromise}>
                            <MembershipPaymentComponent 
                                stripe={stripePromise}
                                updateIsLoading={ (l: boolean) => setIsLoading(l) }
                                handleSuccess={ () => handleSuccess() }
                                handleError={ (e: string) => handleError(e) }/>
                        </Elements>
                    </div>
                }
                
            </div>`
}



