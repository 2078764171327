import React, { useState } from 'react';
import './vpt.css';
import './Page-form-inner.css';


function Challenge(props: any) {

    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

	return (
		<div className="vpt">
			<div className="vpt-page-left-container">
                <div className="vpt-page-left-image-wrapper">
                    <img className="vpt-page-left-image" src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/challenge-hero.png`}/>
                </div>
            </div>
            <div className="vpt-page-right-container">
                <div className="vpt-page-right-header-container">
                    <h2>Noticable results in 14 days.</h2>
                    <p>Based on your input, we have gone ahead and currated a free 14-day workout program for you that will get you the results you deserve.<br/><br/><span>Get started for free by clicking below!</span></p>
                </div>
                <div className="vpt-page-right-button-container">
                    <div onClick={ () => props.nextPressed() } className="vpt-page-right-button">
                        <p>Get started for free</p>
                        <div className="vpt-page-right-button-icon-container">
                            <img className="vpt-page-right-button-icon" src={`${process.env.PUBLIC_URL}/assets/images/icons/btn-arrow.png`}/>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default Challenge;