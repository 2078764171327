import React, { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentMethodMessagingElement, useStripe } from "@stripe/react-stripe-js";
import Mixpanel from '../Mixpanel';

import axios from 'axios';

import './MembershipSelect.css';
import './Page-form-inner.css';

import MembershipElement from '../components-form/MembershipElement';

import Membership_t from '../Interfaces/Membership_t';
import TrainerOnboarding_t from '../Interfaces/TrainerOnboarding_t';




function Membership(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [trainerName, setTrainerName] = useState("Sydney");
    const [trainerImageURL, setTrainerImageURL] = useState(`profile-sydney.png`)
    const [selectedTrainer, setSelectedTrainer] = useState<TrainerOnboarding_t | undefined>(undefined);
    const [membershipOptions, setMembershipOptions] = useState<Membership_t[]>([]);


    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (!props.membershipOptions) return;
        setMembershipOptions(props.membershipOptions);
    }, [props.membershipOptions])

    useEffect(() => {
        console.log("MEMBERSHIP SELECT | props.selectedTrainer", props.selectedTrainer)
        setSelectedTrainer(props.selectedTrainer);
    }, [props.selectedTrainer])

    function membershipPressed(selectedMembership: Membership_t) {
        props.membershipPressed(selectedMembership)
    }

    function liteMembershipPressed() {
        props.liteMembershipPressed();
    }


	return (
        <div className="membership-select page-form-inner">
            <div className="membership-select-left-container">
                <div className="membership-select-left-content">
                    <div className="membership-select-left-image-container">
                        <img className="membership-select-left-image" src={ `${process.env.PUBLIC_URL}/assets/images/coaches/${selectedTrainer !== undefined ? selectedTrainer.imageUrl : 'profile-sydney.png'}` }/>
                    </div>
                    <div className="membership-select-left-info-container">
                        <h2>Train with { selectedTrainer !== undefined ? selectedTrainer.firstName : 'Your Coach' }.</h2>
                        <p>{ selectedTrainer !== undefined ? selectedTrainer.firstName : 'Your Coach' } is excited to meet you, dive into your training goals, and build a program that will help you see results as fast as possible.</p>
                    </div>
                </div>
            </div>
            <div className="membership-select-right-container">
                <div className="membership-card">
                    <div className="membership-card-header">
                        <h2>Select your Membership</h2>
                        <p>All Eigen Fitness Memberships include customized programs, optimized by { selectedTrainer !== undefined ? selectedTrainer.firstName : 'Your Coach' } on a bi-weekly basis, and the <a href="https://eigen.fitness/faq" target="_blank" className="membership-card-link-text">Eigen Fitness Results Guarantee.</a></p>
                    </div>
                    <div className="membership-card-options-wrapper">
                        {
                            membershipOptions.map((item: Membership_t, index: number) => (
                                <MembershipElement
                                    membershipTitle={ item.title }
                                    membershipPrice={ item.price }
                                    membershipCurrency={ item.currency }
                                    membershipInfo={ item.info }
                                    isAvailable={ item.isAvailable }
                                    numNodes={ item.numNodes }
                                    membershipPressed={ () => membershipPressed(item) }
                                    liteMembershipPressed={ () => liteMembershipPressed() }/>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
	)
}

export default Membership;