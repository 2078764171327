import React, { useState, useEffect } from 'react';
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from 'axios';
import firebase from '../Firebase';

import './Payment.css';
import './Page-form-inner.css';
import './MembershipPaymentComponent.css';


const DOMAIN = "https://eigenfitness.com/paymentprocessing" //"http://localhost:4242"

function MembershipPaymentComponent(props: any) {
    const stripe = useStripe();
    const elements: any = useElements();

    const [initSet, setInitSet] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [email, setEmail] = useState('connor_chuk@yahoo.com');
    const [name, setName] = useState('Connor Tester');
    const [userUID, setUserUID] = useState<string | undefined>("_TESTING_UID_");

    const [isLoading, setIsLoading] = useState(false);


    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {

    }

    useEffect(() => {
        if (!props.name) return
        setName(props.name);
    }, [props.name])

    useEffect(() => {
        if (!props.email) return
        setEmail(props.email);
    }, [props.email])

    useEffect(() => {
        if (!props.userUID) return
        setUserUID(props.userUID);
    }, [props.userUID])

    

    const createCustomer = async () => {

        return new Promise<string>((resolve, reject) => {
            let sendObj = {
                name: name,
                email: email,
                user_uid: userUID
            }

            axios.post(`${DOMAIN}/create-customer`,
                sendObj,
                { headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',} 
            })
            .then(response => {
                let resp = response.data;
                console.log("create-customer RESP:", resp);

                resolve(resp.id);
            })
            .catch(e => {
                console.log("ERROR from create-customer:", e);
                reject(e);
            })
        })
    }

    const attachPaymentMethodToCustomer = async (customer_id: string, payment_method_id: string) => {

        return new Promise<string>((resolve, reject) => {
            let sendObj = {
                customer_id: customer_id,
                payment_method_id: payment_method_id
            }

            axios.post(`${DOMAIN}/attach-customer-payment-method`,
                sendObj,
                { headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',} 
            })
            .then(response => {
                let resp = response.data;
                console.log("attach-customer-payment-method RESP:", resp);

                resolve(resp.id);
            })
            .catch(e => {
                console.log("ERROR from attach-customer-payment-method:", e);
                reject(e);
            })
        })
    }

    const updateCustomerDefaultPaymentMethod = async (customer_id: string, payment_method_id: string) => {

        return new Promise<string>((resolve, reject) => {
            let sendObj = {
                customer_id: customer_id,
                payment_method_id: payment_method_id
            }

            axios.post(`${DOMAIN}/update-customer-default-payment-method`,
                sendObj,
                { headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',} 
            })
            .then(response => {
                let resp = response.data;
                console.log("update-customer-default-payment-method RESP:", resp);

                resolve(resp);
            })
            .catch(e => {
                console.log("update-customer-default-payment-method:", e);
                reject(e);
            })
        })
    }

    

    const createSubscription = (clientID: string) => {

        return new Promise<{ subscription_id: string, price_id: string }>((resolve, reject) => {
            let sendObj = {
                customer_id: clientID,
                price_key: "lite"
            }

            axios.post(`${DOMAIN}/create-subscription`,
                sendObj,
                { headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',} 
            })
            .then(response => {
                let resp = response.data;
                console.log("create-subscription RESP:", resp);

                resolve({
                    subscription_id: resp.subscription_id,
                    price_id: resp.price_id
                });
            })
            .catch(e => {
                console.log("ERROR from create-subscription:", e);
                reject(e);
            })
        })
    }

    const handleSubmit = async (event: any) => {


        console.log("handleSubmit", event);
        event.preventDefault();

        if (!elements || !stripe) return;

        elements.on('update', function (e: any) {
           console.log(e);
       })

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            // Show error to your customer
            setErrorMessage(submitError.message);
            return;
        } 

        setIsLoading(true);

        // 1. Create a customer 
        var customerId = ""
        try {
            customerId = await createCustomer();
        } catch (e) {
            console.log("Error creating customer", e);
            setIsLoading(false);
            return;
        }

        console.log("proceeding with customer", customerId);

        // 2. Create payment method
        let paymentMethod = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name: name
            }
        })
        let paymentMethodId = paymentMethod && paymentMethod.paymentMethod ? paymentMethod.paymentMethod.id : "";
        console.log("paymentMethod:", paymentMethod)

        // 3. Attach payment method to customer
        try {
            let resp_attach = await attachPaymentMethodToCustomer(customerId, paymentMethodId);
        } catch (e) {
            console.log("Error attaching payment method to customer", e);
            setIsLoading(false);
            return;
        }

        // 4. Make attached payment method the customer's default
        try {
            let resp_customerDefault = await updateCustomerDefaultPaymentMethod(customerId, paymentMethodId);
        } catch (e) {
            console.log("Error making payment method customer's default", e);
            setIsLoading(false);
            return;
        }
        
        // 5. Create subscription
        var subscriptionResponse: { subscription_id: string, price_id: string } = { subscription_id: "", price_id: "" }; 
        try {
            subscriptionResponse = await createSubscription(customerId);
        } catch (e) {
            console.log("Error creating subscription", e);
            setIsLoading(false);
            return;
        }

        // 6. Configure in firebase
        if (!userUID || userUID === "") {
            console.log("USER UID IS NOT FOUND:", userUID);
        }
        let database = firebase.database();
        let ref = database.ref(`payment_details/${userUID}`);
        await ref.set({
            customer_id: customerId,
            status: 'trial'
        });

        await ref.child('membership_history').push({
            price_id: subscriptionResponse.price_id,
            subscription_id: subscriptionResponse.subscription_id,
            timestamp: Date.now()
        })

        props.handleSuccess();
        setIsLoading(false);

    }

    const createPaymentMethod = () => {
        // if (!stripe) return;

        // console.log(cardElement)
        // let paymentMethod = stripe.createPaymentMethod({
        //     type: 'card',
        //     card: cardElement as any,
        //     billing_details: {
        //         name: 'Jimmy Jones'
        //     }
        // })

        // console.log(paymentMethod)
    }

    const cardElementUpdated = (e: any) => {
        console.log("EE:", e);
    }

    return (
        <form onSubmit={handleSubmit}>
            <CardElement onChange={(e: any) => cardElementUpdated(e)}/>

            {
                isLoading ? 
                <div className="payment-info-loading-container">
                    <div className="payment-loading-spinner"/>
                    <p>Loading...</p>
                </div>
                :
                <button type="submit" disabled={!stripe || !elements} className="payment-summary-button">
                    <p>Train with { props.trainerName }</p>
                </button>
            }
            { errorMessage && <div>{errorMessage}</div> }
        </form>
        
    )
}

export default MembershipPaymentComponent;

/*
<div className="">
            { cardElement }
            <div onClick={() => createPaymentMethod() } className="payment-summary-button">
                <p>Get My Program</p>
            </div>
        </div>
*/