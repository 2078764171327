// Import Ionic Elements, React and Supporting Elements/Libraries
import React, { useState } from 'react';
import { getPlatforms } from '@ionic/react';
import { Switch, Route, Redirect, useHistory, useParams, useRouteMatch, useLocation } from "react-router-dom";
import Mixpanel from '../Mixpanel';
import { IonReactRouter } from '@ionic/react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// Import Libraries
import firebase from '../Firebase';
import axios from 'axios';

// Import Interfaces
import Membership_t from '../Interfaces/Membership_t';
import TrainerOnboarding_t from '../Interfaces/TrainerOnboarding_t';


// Import CSS Files
import './Main.css';

// Import components
import Intro from './Intro';
import Name from './Name';
import Age from './Age';
import Gender from './Gender';
import Style from './Style';
import Drive from './Drive';
import Experience from './Experience';
import Improving from './Improving';
import Weekdays from './Weekdays';
import Injury from './Injury';
import ActiveLevel from './ActiveLevel';
import UniqueNeeds from './UniqueNeeds';
import WorkoutLocation from './WorkoutLocation'; //nothing wrong here
import Training from './TrainingIntend';
import VPT from './vpt';
import TrainingIntend from './TrainingIntend';
import TrainerDetails from './TrainerDetails';
import TrainerIntensity from './TrainingIntensity';
import LoadingCoach from './LoadingCoach';
import CoachDisplay from './CoachDisplay';
import Email from './Email';
import Payment from './Payment';
import Membership from './Membership';
import MembershipSelect from './MembershipSelect';
import Confirm from './Confirm';
import Program from './Program';
import Calendar from './Calendar';
import Challenge from './Challenge';


function Dashboard(props: any) {

	/**
	 *	Decleare variables and constants to be used throughout Dashboard, or initializing objects
	 *  ----------------------------------------------------------------------------------------------------
	 */

	const pages = [
		'intro', 
		'name', 
		'age', 
		'gender', 
		'activeLevel', 
		'drive',
		//'style',
		'experience', 
		'workoutLocation', 
		'uniqueNeeds', 
		// 'improving', 
		'injury', 
		'weekdays', 
		'vpt',
		'trainIntend', 
		'TrainerDetails', 
		'trainerIntensity', 
		'email', 
		'loadingCoach', 
		'coachDisplay', 
		'select-membership',
		'membership', 
		'calendar',
		'confirm'
	]; 

	const pages_noTrain = [
		//...trainIntend,
		'challenge',
		'email',
		'program',
		'confirm'
	];

	const membershipOptions: Membership_t[] = [
        {
            title: "Lite Membership",
            price: 99,
            price_key: 'lite',
            currency: 'US$',
            info: "Your Lite Membership comes with unlimited Coach messaging, 24h response time, and bi-weekly video checkins with your Coach.",
            isAvailable: true,
            numNodes: 0
        },
        {
            title: "Plus Membership",
            price: 149,
            price_key: 'plus',
            currency: 'US$',
            info: "Your Lite Membership comes with unlimited Coach messaging, 24h response time, and bi-weekly video checkins with your Coach.",
            isAvailable: false,
            numNodes: 3
        },
        {
            title: "Ultimate Membership",
            price: 199,
            price_key: 'ultimate',
            currency: 'US$',
            info: "Your Lite Membership comes with unlimited Coach messaging, 24h response time, and bi-weekly video checkins with your Coach.",
            isAvailable: false,
            numNodes: 5
        }
    ]

    const trainerOptions: TrainerOnboarding_t[] = [
    	{
    		firstName: 'Connor',
    		displayName: 'Connor H.',
			fullName: 'Connor Holowachuk',
			email: 'connor@eigenfitness.com',
			blurb: 'Connor believes that data-backed training is the fastest way to success.',
			title: 'Expert Coach',
			imageUrl: 'profile-connor.png',
			uid: 'CLCA6mbza8TNpJcdaWAnM2Ea5jS2',
			isAvailable: true,
			calendlyLink: 'https://calendly.com/connor-hol/first-coaching-consultation'
    	},
    	{
    		firstName: 'Zach',
    		displayName: 'Zach H.',
			fullName: 'Zach Higgins',
			email: 'zach@eigenfitness.com',
			blurb: 'A competitive rower, Zach has an extensive background in the gym and training cardio.',
			title: 'Expert Coach',
			imageUrl: 'profile-zach.png',
			uid: 'nU93EH0guoMYKqg2qc4Wiooe1A73',
			isAvailable: true,
			calendlyLink: 'https://calendly.com/eigen-zach/virtual-training-consultation'
    	},
    	{
    		firstName: 'Sydney',
    		displayName: 'Sydney S.',
			fullName: 'Sydney Stuart',
			email: 'hey@eigenfitness.com',
			blurb: 'As a competing bodybuilder, Sydney has years of experience gaining muscle and cutting weight.',
			title: 'Expert Coach',
			imageUrl: 'profile-sydney.png',
			uid: 'CLCA6mbza8TNpJcdaWAnM2Ea5jS2',
			isAvailable: false,
			calendlyLink: ''
    	}		
	]

	const [initSet, setInitSet] = useState(false);
	const [platformIsTablet, setPlatformIsTablet] = useState(false);

	const [pageIndex, setPageIndex] = useState(0);
	const [previousPathIndex, setPreviousPathIndex] = useState(0);
	const [selectedStyle, setSelectedStyle] = useState<{ title: string, id: any } | undefined>(undefined);

	const [name, setName] = useState('');
	const [gender, setGender] = useState('male');
	const [active, setActiveLevel] = useState('');
	const [uniqueNeeds, setUniqueNeeds] = useState('');
	const [workoutLocation, setWorkOutLocation] = useState('');
	const [trainingIntend, setTrainingIntend] = useState('');
	const [trainerDetails, setTrainerDetails] = useState('');
	const [trainerIntensity, setTrainerIntensity] = useState('');
	const [age, setAge] = useState('');
	const [email, setEmail] = useState('');
	const [experience, setExperience] = useState('');
	const [goal, setGoal] = useState('');
	const [coach, setCoach] = useState('');
	const [trainingDays, setTrainingDays] = useState<string[]>([]);
	const [injuries, setInjuries] = useState<string[]>([]);

	const [userUID, setUserUID] = useState("")
	const [klaviyoID, setKlaviyoID] = useState("")
	const [verificationKey, setVerificationKey] = useState("")

	const [selectedMembership, setSelectedMembership] = useState<Membership_t>(membershipOptions[0]);
	const [selectedTrainer, setSelectedTrainer] = useState<TrainerOnboarding_t | undefined>(undefined);

	const [isLoading, setIsLoading] = useState(false);
	const [programResults, setProgramResults] = useState<any>({});

	const [programError, setProgramError] = useState(false);

	let history = useHistory();											// Instance of useHistory for path redirects
	const location = useLocation();

	/**
	 *	Initializing functions. 
	 *  ----------------------------------------------------------------------------------------------------
	 */

	if (initSet === false) {
		setInitSet(true);

		let pfs: string[] = getPlatforms();
		let isTablet = pfs.includes("tablet");
		setPlatformIsTablet(isTablet);

		history.push(`/main`);
	}



	//if (profileLoaded === false) { getUserProfile(); }

	/**
	 *	Page change handling methods
	 *  ----------------------------------------------------------------------------------------------------
	 */
	async function pageChanged(toIndex: number) {

		history.push(`/main/${pages[toIndex]}`);
		setPageIndex(toIndex);

		console.log("MOVING TO:", toIndex, pages[toIndex]);

		Mixpanel.track("Onboarding page updated", { new_page_index: toIndex, new_page_name: pages[toIndex] });

		setPreviousPathIndex(toIndex);

	}

	async function pageChanged_noTrain(toIndex: number) {

		history.push(`/main/${pages_noTrain[toIndex]}`);
		setPageIndex(toIndex);

		console.log("MOVING TO:", toIndex, pages_noTrain[toIndex]);

		Mixpanel.track("Onboarding page updated", { new_page_index: toIndex, trainer: "no", new_page_name: pages_noTrain[toIndex] });

		setPreviousPathIndex(toIndex);

	}

	function restartProcess() {
		history.push(`/main/style`);
		setPageIndex(4);
	}


	function getProgressBarWidthPercentage() {
		if (trainingIntend === 'already' || trainingIntend === 'no') return 100;
		let maxLength = 18; //pages.length - 3;
		let per = (pageIndex - 1) / maxLength * 100;;
		return per > 100 ? 100 : per;
	}


	function nextPagePressed() {
		pageChanged(pageIndex + 1);
		//getResults();
	}


	function nextPagePressedSkip() {
		pageChanged(pageIndex + 7);
		//getResults();
	}

	function nextPagePressed_withSubmit() {

		emailUpdated();

		console.log({
			name: name,
			age: age,
			email: email,
			style: selectedStyle === undefined ? '' : selectedStyle.id,
			experience: experience,
			goal: goal,
			training_days: trainingDays,
			injuries: injuries
		})
		pageChanged(pageIndex + 1);
		//getResults();
	}

	function replaceAllBackSlash(targetStr: string) {
		var index = targetStr.indexOf("\\");
		while (index >= 0) {
			targetStr = targetStr.replace("\\", "");
			index = targetStr.indexOf("\\");
		}
		return targetStr;
	}



	function unlockProgram() {
		//mailProgram();
		//configureNewUser();

		nextPagePressed();
		markOnboardingComplete();
	}


	function emailUpdated() {

		setEmail(email);

		axios.get(`https://eigenfitness.com/email/addToMailList_klavyio.php?email=${email}&firstName=${name}&lastName=null&city=null&referrence=null`,
			{ headers: { 'Content-Type': 'application/json' } })
			.then(response => {
				let resp = response.data;
				console.log("Klavyio user ID resp:", resp);
			})
			.catch(e => {
				console.log("ERROR from adding user to Klavyio:", e);
			})
	}

	async function newUserCreated(email: string, firebaseUID: string, klaviyo_ID: string) {
		// 1. set state variable for email and firebase UID
		setEmail(email);
		setUserUID(firebaseUID);
		setKlaviyoID(klaviyo_ID);
		if (trainingIntend === 'yes') {
			pageChanged(pageIndex + 1);
		} else {
			// move to alternate page flow for no training
			let currentIndex = pages_noTrain.indexOf('email');
			pageChanged_noTrain(currentIndex + 1);
		}
		

		console.log("newUserCreated | input = ", email, firebaseUID, klaviyo_ID);

		// 2. Update client profile in database
		let nameSplit = (name ?? "Test User").split(" ");
        let fName = nameSplit[0] ?? "Test";
        let lName = nameSplit[nameSplit.length - 1] ?? "User";

        let userProfileObject = {
        	first_name: fName,
        	last_name: lName,
        	full_name: name,
        	email: email,
        	klaviyoID: klaviyo_ID,
        	age: age,
        	gender: gender,
        	trainerIntensity: trainerIntensity,
        	trainerType: trainerDetails,
        	goal: goal,
        	training: trainingIntend,
        	profileCreatedTimestamp: Date.now(),
        	height: -1,
        	weight: -1
        };

        let database = firebase.database();
        let ref_user_profile = database.ref(`user_profile/${firebaseUID}`);
        if (firebaseUID === "") return;
        await ref_user_profile.set(userProfileObject);


        // 6. Indicate that user needs to set password
        let thisDate = new Date();
        let thisDay = thisDate.getDate();
        let thisMo = thisDate.getMonth();
        let thisYr = thisDate.getFullYear();
        let thisDateString = `${thisDay < 10 ? '0' : ''}${thisDay}-${thisMo < 10 ? '0' : ''}${thisMo}-${thisYr}`

        let ref_users_need_verification = database.ref(`/users_need_verification`);
		let verificationResp = await ref_users_need_verification.push({
			id: firebaseUID,
			full_name: name,
			email: email,
			trainer_id: "",
			timestamp: Date.now(),
			formatted_timestamp: thisDateString
		}); 

		setVerificationKey(verificationResp.key ? verificationResp.key : "");
	}

	function checkIfPageIsExemptFromPadding() {
		let exemptPageList = ['intro', 'vpt', 'select-membership', 'membership', 'calendar']
		let currentPageName = pages[pageIndex];

		return exemptPageList.includes(currentPageName)
	}

	function membershipSelected(m: Membership_t) {
		setSelectedMembership(m);
		nextPagePressed();
	}

	async function updateSelectedTrainer(t: TrainerOnboarding_t) {
		setSelectedTrainer(t);
		nextPagePressed();

		// 1. Update their preferences in klaviyo
		try {
			let resp_a = await axios.get(`https://eigenfitness.com/email/updateProfile_klaviyo_trainer.php?id=${ klaviyoID }&trainer_name=${ t.fullName }&trainer_uid=${ t.uid }`,
				{ headers: { 'Content-Type': 'application/json' } });
			console.log("Klavyio user update resp:", resp_a);
		} catch (e) {
			console.log("ERROR from updating user in Klavyio:", e);
		}

		// 2. Notify trainer of client if neccessary
		try {
			let resp_mail_trainer = await axios.get(`https://eigenfitness.com/email/alert_trainer_new_client.php?trainer_name=${ t.displayName }&email=${ t.email }&client_name=${ name }&client_email=${ email }&client_uid=${ userUID }`,
				{ headers: { 'Content-Type': 'application/json' } });
			console.log("Mail to trainer update resp:", resp_mail_trainer);
		} catch (e) {
			console.log("ERROR from updating user in Klavyio:", e);
		}

		// 3. Update client trainer ref in db
		let database = firebase.database();
		if (userUID === "") return;
		let ref_client_trainers = database.ref(`client_trainers/${ userUID }/0`);
		await ref_client_trainers.set(t.uid);

		// 4. Update trainer client ref
		let thisDate = new Date();
        let thisDay = thisDate.getDate();
        let thisMo = thisDate.getMonth();
        let thisYr = thisDate.getFullYear();
        let thisDateString = `${thisDay < 10 ? '0' : ''}${thisDay}-${thisMo < 10 ? '0' : ''}${thisMo}-${thisYr}`

        if (t.uid === "") return;
        let ref_trainer_clients = database.ref(`personal_trainer_clients/${t.uid}/clients/${userUID}`);
        await ref_trainer_clients.set({
            date_added: thisDateString,
            full_name: name,
            last_workout_date: "00-00-0000",
            timestamp_added: Date.now(),
            workouts_together: 0
        });
        
        // 5. Update trainer ID in users need verification
        if (verificationKey === "") return; 
        let ref_users_need_verification = database.ref(`/users_need_verification/${verificationKey}/trainer_id`);
        ref_users_need_verification.set(t.uid)
         
	}

	async function updateTrainingIntend(tr: string) {
		setTrainingIntend(tr);

		if (tr === 'yes') {
			nextPagePressed()
		} else {
			pageChanged_noTrain(0);
		}

		// Update their preferences in klaviyo
		if (tr !== 'yes') {
			try {
				let resp_b = await axios.get(`https://eigenfitness.com/email/updateProfile_klaviyo_noTrainer.php.php?id=${ klaviyoID }`,
					{ headers: { 'Content-Type': 'application/json' } });
				console.log("Klavyio user update resp:", resp_b);
			} catch (e) {
				console.log("ERROR from updating user in Klavyio:", e);
			}
		}
	}

	async function markOnboardingComplete() {

		let database = firebase.database();
		if (userUID === "") return;
		let ref = database.ref(`user_profile/${userUID}/onboarding_complete`);
		await ref.set(true);
		
	}
	

	return (
		<div className={`main ${platformIsTablet ? 'main-tablet' : ''}`}>
			<div className="main-header-progress-bar" style={{ width: `${getProgressBarWidthPercentage()}%` }}></div>
			<div className={`main-header ${pageIndex === 0 || pageIndex === 11 ? 'main-header-transparent' : ''}`}>
				<div className="main-header-inner main-content">
					<div className="main-header-logo-container">
						<h3>eigen<span>fitness</span></h3>
					</div>
				</div>
			</div>
			<div className={`main-content-wrapper ${checkIfPageIsExemptFromPadding() ? 'main-content-wrapper-no-padding' : ''}`}>
				<div className={`main-content ${checkIfPageIsExemptFromPadding() ? 'main-content-no-padding' : ''}`}>
					<div className={`main-content-container ${checkIfPageIsExemptFromPadding() ? 'main-content-container-no-padding' : ''}`}>
						<div className="switch-container">
							<TransitionGroup>
								<CSSTransition key={location.key} timeout={500} classNames="slide" unmountOnExit>
									<Switch location={location}>
										<Route exact path={`/main/intro`}>
											<Intro
												nextPressed={() => nextPagePressed()}/>
										</Route>
										<Route exact path={`/main/name`}>
											<Name
												nextPagePressed={() => nextPagePressed()}
												nameUpdated={(n: string) => setName(n)} />
										</Route>
										<Route exact path={`/main/age`}>
											<Age
												ageUpdated={(a: string) => setAge(a)}
												nextPagePressed={() => nextPagePressed()}
												name={name} />
										</Route>
										<Route exact path={`/main/gender`}>
											<Gender
												genderUpdated={(g: string) => setGender(g)}
												nextPagePressed={() => nextPagePressed()}
												name={name} />
										</Route>
										<Route exact path={`/main/drive`}>
											<Drive
												driveSelected={(d: string) => null}
												nextPagePressed={() => nextPagePressed()}
												name={name} />
										</Route>
										<Route exact path={`/main/activeLevel`}>
											<ActiveLevel
												setActiveLevel={(al: string) => setActiveLevel(al)}
												nextPagePressed={() => nextPagePressed()}
												name={name} />
										</Route>
										<Route exact path={`/main/style`}>
											<Style
												nextPagePressed={() => nextPagePressed()}
												styleSelected={(s: { title: string, id: any }) => setSelectedStyle(s)} />
										</Route>
										<Route exact path={`/main/uniqueNeeds`}>
											<UniqueNeeds
												setUniqueNeeds={(dr: string) => setUniqueNeeds(dr)}
												nextPagePressed={() => nextPagePressed()}
												name={name} />
										</Route>
										<Route exact path={`/main/coachDisplay`}>
											<CoachDisplay
												trainerOptions={ trainerOptions }
												updateSelectedTrainer={ (t: TrainerOnboarding_t) => updateSelectedTrainer(t) }
												name={name} />
										</Route>

										<Route exact path={`/main/loadingCoach`}>
											<LoadingCoach
												nextPagePressed={() => nextPagePressed()}
											/>
										</Route>
										<Route exact path={`/main/workoutLocation`}>
											<WorkoutLocation
												setWorkOutLocation={(dr: string) => setWorkOutLocation(dr)}
												nextPagePressed={() => nextPagePressed()}
												name={name} />
										</Route>
										<Route exact path={`/main/vpt`}>
											<VPT 
												nextPressed={() => nextPagePressed()}/>
										</Route>
										<Route exact path={`/main/trainIntend`}>
											<TrainingIntend
												setTrainingIntend={(tr: string) => updateTrainingIntend(tr)}
												nextPagePressed={() => null}
												nextPagePressedSkip={() => null}
												name={name} />
										</Route>
										<Route exact path="/main/TrainerDetails">
											<TrainerDetails
												setTrainerDetails={(tr: string) => setTrainerDetails(tr)}
												trainerOptions={ trainerOptions }
												nextPagePressed={() => nextPagePressed()}
												name={name} />
										</Route>

										<Route exact path="/main/trainerIntensity">
											<TrainerIntensity
												setTrainerIntensity={(tr: string) => setTrainerIntensity(tr)}
												nextPagePressed={() => nextPagePressed()}
												name={name} />
										</Route>
										<Route exact path={`/main/experience`}>
											<Experience
												style={selectedStyle}
												experienceUpdated={(e: string) => setExperience(e)}
												nextPagePressed={() => nextPagePressed()} />
										</Route>
										<Route exact path={`/main/improving`}>
											<Improving
												goalUpdated={(e: string) => setGoal(e)}
												nextPagePressed={() => nextPagePressed()} />
										</Route>
										<Route exact path={`/main/weekdays`}>
											<Weekdays
												weekdaysUpdated={(w: string[]) => setTrainingDays(w)}
												nextPagePressed={() => nextPagePressed()} />
										</Route>
										<Route exact path={`/main/injury`}>
											<Injury
												injuriesUpdated={(i: string[]) => setInjuries(i)}
												nextPagePressed={() => nextPagePressed()} />
										</Route>
										<Route exact path={`/main/email`}>
											<Email
												name={name}
												hasTrainer={ trainingIntend === 'yes' }
												newUserCreated={ (email: string, firebase_uid: string, klaviyo_id: string) => newUserCreated(email, firebase_uid, klaviyo_id) }
												nextPagePressed={ () => nextPagePressed_withSubmit() } />
										</Route>
										<Route exact path={`/main/payment`}>
											<Payment
												name={name}
												email={email}
												paymentSuccess={() => unlockProgram()} />
										</Route>
										<Route exact path={`/main/select-membership`}>
											<MembershipSelect
												selectedTrainer={ selectedTrainer }
												membershipOptions={ membershipOptions }
												membershipPressed={ (m: Membership_t) => membershipSelected(m) }
												liteMembershipPressed={ () => membershipSelected(membershipOptions[0]) }/>
										</Route>
										<Route exact path={`/main/membership`}>
											<Membership
												name={ name }
												email={ email }
												userUID={ userUID }
												selectedTrainer={ selectedTrainer }
												paymentSuccess={() => unlockProgram()}
												backToMembershipsPressed={ () => null }/>
										</Route>
										<Route exact path={`/main/calendar`}>
											<Calendar
												name={ name }
												email={ email } 
												selectedTrainer={ selectedTrainer }
												nextPressed={() => nextPagePressed()}/>
										</Route>
										
										<Route exact path={`/main/program`}>
											<Program
												userUID={ userUID }
												unlockProgram={() => pageChanged_noTrain(pages_noTrain.length - 1)} />
										</Route>
										<Route exact path={`/main/challenge`}>
											<Challenge
												nextPressed={() => pageChanged_noTrain(1)}/>
										</Route>

										<Route exact path={`/main/confirm`}>
											<Confirm
												selectedTrainer={ selectedTrainer }
												hasTrainer={ trainingIntend === 'yes' }
												isLoading={ isLoading }
												email={ email } />
										</Route>
									</Switch>
								</CSSTransition>
							</TransitionGroup>

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Dashboard;