import React, { useState, useEffect } from 'react';

// Import Libraries
import firebase from '../Firebase';
import axios from 'axios';

import './Program.css';


import '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';


const workouts: any = {
    "male": [ 
        "-NY4m-ug8Yg0tMAEFG9f",
        "-NY5JgzD4AaFxvV60Cne",
        "-NY5LDK5zpOom9CyzQh3",
        "",
        "-NY5M2socsTZwQlZLj-z",
        "-NY5M_8dzArQ9vRSxoP0",
        "-NY5NYBCTCmiiDo8oTyF",
        "",
        "-NY5Nv4OkLcBPYs6Tr6i",
        "-NY5OPDo2WcU3Ek_yOsf",
        "-NY5P131mEmWTImL0zaS",
        "",
        "-NY5PYk7B1B5C0AF3oPz",
        "-NY5QNq8EG2o_dOAZ4kB"
    ],
    "female": [ 
        "-NY4m-ug8Yg0tMAEFG9f",
        "-NY5JgzD4AaFxvV60Cne",
        "-NY5LDK5zpOom9CyzQh3",
        "",
        "-NY5M2socsTZwQlZLj-z",
        "-NY5M_8dzArQ9vRSxoP0",
        "-NY5NYBCTCmiiDo8oTyF",
        "",
        "-NY5Nv4OkLcBPYs6Tr6i",
        "-NY5OPDo2WcU3Ek_yOsf",
        "-NY5P131mEmWTImL0zaS",
        "",
        "-NY5PYk7B1B5C0AF3oPz",
        "-NY5QNq8EG2o_dOAZ4kB"
    ]
}

function Program(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [programLoaded, setProgramLoaded] = useState(false);
    const [numberOfRebuilds, setNumberOfRebuilds] = useState(0);

    const [primed, setPrimed] = useState(false);

    if (!initSet) setInitSet(true)

    useEffect(() => {
        if (initSet) {
            composeProgram("male", props.userUID ?? "_TESTING_UID_");
        }
    }, [initSet])

    useEffect(() => {
        if (primed) props.unlockProgram();
        
    }, [primed])

    const composeProgram = async (targetGender: "male" | "female", clientID: string) => {
        if (!clientID || clientID === "") return;

        let programList: string[] = workouts[targetGender];

        for (var i = 0; i < 14; i++) {
            let thisDateTS = Date.now() + (1000 * 3600 * 24 * i);
            let thisDate = new Date(thisDateTS);
            let thisDay = thisDate.getDate();
            let thisMo = thisDate.getMonth();
            let thisYr = thisDate.getFullYear();

            let thisWorkoutID = programList[i] ?? "";

            try {
                await addWorkoutToDate(thisDateTS, thisDay, thisMo, thisYr, thisWorkoutID, clientID);
            } catch (err) {
                console.log();
            }
        }

        console.log("-- FINISHED ADDING PROGRAM TO USER PROFILE", clientID);
        setTimeout(() => {
            setPrimed(true);
        }, 1500);
        
    }

    const addWorkoutToDate = async (ts: number, day: number, mo: number, yr: number, workoutID: string, clientID: string) => {

        return new Promise( async (resolve, reject) => {
            try {

                if (workoutID === "") resolve("");

                if (clientID !== "") {
                    let location = `workout_plans/client_assigned_plans/${clientID}/schedule/${yr}/${mo}/${day}`;
                    let postObj = {
                        cycle: { id: "", index: 0, name: "Cycle 1", path: "" },
                        phase: { id: "", index: 0, name: "14 day Challenge", path: "" },
                        path: "",
                        timestamp: ts,
                        workout_id: workoutID
                    }
                    let resp = await setDatabaseDirect(location, postObj);
                    resolve(resp);
                } else {
                    reject("No client ID provided")
                }
                
            } catch (err) {
                reject(err);
            }
        })
    }

    const setDatabaseDirect = async (location: string, postObj: any) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let database = firebase.database();
                let ref = database.ref(location);
                await ref.set(postObj);
                resolve("Complete");
            } catch (err) {
                reject(err);
            }
        })
    }

	return (
		<div className="program page-form-inner">
			<div hidden={!isLoading} className="page-form-inner-question-container">
                <h2>Your program is being generated...</h2>
                <p>This will only take a few seconds!</p>
            </div>
            <div hidden={isLoading || errorOccurred} className="page-form-inner-question-container">
                <h2>Your AI-powered program is ready for you!</h2>
                
            </div>
            <div hidden={isLoading || !errorOccurred} className="page-form-inner-question-container">
                <h2>Uh oh! Our AI had a brain fart.</h2>
                <p>It looks like an error occurred when crafting your program.<br/>It's nothing you did, this is on our end.<br/>Press "Rebuild Program" below to try again.</p>
            </div>
            <div className="page-form-inner-input-container">

                <div hidden={!isLoading} className="page-form-inner-loading-container">
                    <svg className="gegga">
                        <defs>
                            <filter id="gegga">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="7" result="blur"></feGaussianBlur>
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 20 -10" result="inreGegga"></feColorMatrix>
                                <feComposite in="SourceGraphic" in2="inreGegga" operator="atop"></feComposite>
                            </filter>
                        </defs>
                    </svg>
                    <svg className="snurra" width="200" height="200" viewBox="0 0 200 200" style={{marginLeft: 0}}>
                        <defs>
                            <linearGradient id="linjärGradient">
                                <stop className="stopp1" offset="0"></stop>
                                <stop className="stopp2" offset="1"></stop>
                            </linearGradient>
                            <linearGradient y2="160" x2="160" y1="40" x1="40" gradientUnits="userSpaceOnUse" id="gradient" href="#linjärGradient"></linearGradient>
                        </defs>
                        <path className="halvan" d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776 -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64 0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736 -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64 64,-64"></path>
                        <circle className="strecken" cx="100" cy="100" r="64"></circle>
                    </svg>
                    
                </div>
            </div>
            <div hidden={isLoading || !programLoaded || errorOccurred} className="page-form-inner-confirm-button-container">
                
            </div>
		</div>
	)
}

export default Program;