import React, { useState, useEffect } from 'react';
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import './Calendar.css';
import './Page-form-inner.css';


function Calendar(props: any) {

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => completeScheduleing(e) ,
    });

    const [trainerName, setTrainerName] = useState("your Coach")
    const [userName_full, setUserName_full] = useState("")
    const [userName_first, setUserName_first] = useState("")
    const [userName_last, setUserName_last] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [initSet, setInitSet] = useState(false);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        setTrainerName(props.trainerName ?? "your Coach");
    }, [props.trainerName])

    useEffect(() => {
        if (!props.name) return;


        let nameSplit: string[] = props.name.split(" ");
        let fName = nameSplit[0] ?? "";
        let lName = nameSplit.length > 1 ? (nameSplit[nameSplit.length - 1] ?? "") : "";

        setUserName_full(props.name);
        setUserName_first(fName);
        setUserName_last(lName);

    }, [props.name])

    useEffect(() => {
        if (!props.email) return;
        setUserEmail(props.email)
    }, [props.email])

    function initialize() {
        
    }

    function completeScheduleing(e: any) {
        console.log(e.data.payload)
        props.nextPressed();
    }

    return (
        <div id="root" className="intro calendar">
            <div className="calendar-container">
                <div className="calendar-container-left">
                    <div className="calendar-container-left-header-container">
                        <h2>Book your free consultation with { trainerName }</h2>
                        <p>Your new Coach is excited to introduce themselves and chat with you soon!<br/><br/><span>Book a time slot with { trainerName } to review your goals, a general overview of nutrition, and to build a plan that works for you.</span></p>
                    </div>
                </div>
                <div className="calendar-container-right">
                    <div className="calendar-content">
                        <InlineWidget 
                            url="https://calendly.com/connor-hol/first-coaching-consultation" 
                            prefill={{
                                email: userEmail,
                                firstName: userName_first,
                                lastName: userName_last,
                                name: userName_full}}/>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Calendar;