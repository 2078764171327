import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';
import './Email.css';
import './Page-form-inner.css';

// Import Libraries
import firebase from '../Firebase';
import axios from 'axios';


import '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

const apiURL = 'https://eigenfitness.com/paymentprocessing' // 'http://localhost:4242'

function Email(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [okayToProceed, setOkayToProceed] = useState(false);
    const [inputString, setInputString] = useState('');
    const [inputFocused, setInputFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    useEffect(() => {
        attemptApproval();
        if (props.emailUpdated !== undefined) {
            //props.emailUpdated(inputString);
        }
    }, [inputString])

    function initialize() {
        
    }


    function inputUpdated(e: any) {
        if (e === "Enter") {
            nextPagePressed();
        }
    }

    function inputChanged(toString: any, forInput: string) {
        setInputString(toString);
        attemptApproval();
        setErrorMessage('');
    }

    function attemptApproval() {
        let emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        let passedEmailRegEx = emailRegEx.test(inputString);
        setOkayToProceed(passedEmailRegEx)
    }

    function nextPagePressed() {
        if (isLoading) return;
        if (okayToProceed === true) runUserCreation();
    }

    async function runUserCreation() {

        setIsLoading(true);

        let email = inputString;

        // 1. Add user to Klayvio
        let resp_klavyio = await axios.get(`https://eigenfitness.com/email/addToMailList_klavyio.php?email=${email}&firstName=${props.name ?? "Test"}&lastName=null&city=null&referrence=null`, 
            { headers: { 'Content-Type': 'application/json' } })
        .then((resp_klavyio: any) => {
            console.log("Klavyio user ID resp:", resp_klavyio.data);
            var klavyio_id = resp_klavyio.data ?? "";
            addToFirebase(klavyio_id);
        })
        .catch((error: any) => {
            console.log("Klaviyo error:", error);
            setIsLoading(false);
        })     
    }

    function addToFirebase(klavyio_id: string) {

        console.log("addToFirebase | klavyio_id", klavyio_id);

        let email = inputString;

        // 2. Add user to firebase
        let pw_temp = "i8pa0ARrtSF";
        var CryptoJS = require("crypto-js");
        var ciphertext_password = CryptoJS.AES.encrypt(pw_temp, 'password_i8pa0ARrtSF');
        let encyrptedPassword = ciphertext_password.toString();

        var ciphertext_email = CryptoJS.AES.encrypt(email, 'email_i8pa0ARrtSF');
        let encyrptedEmail = ciphertext_email.toString();

        let userObject = {
            full_name: props.name,
            encyrpted_email: encyrptedEmail,
            encyrpted_password: encyrptedPassword
        };

        console.log("addToFirebase | ", userObject);

        // axios.post(`${apiURL}/create-user`, 
        axios.post(`https://us-central1-movement-tracker-457bc.cloudfunctions.net/createUser`, 
            userObject,
            { headers: { 'Content-Type': 'application/json' } })
        .then((resp_createUser: any) => {
            console.log("resp_createUser", resp_createUser);
            if (resp_createUser.data.uid !== undefined) {
                let user_uid = resp_createUser.data.uid ?? "";
                console.log("addToFirebase | newUserCreated A", email, user_uid, klavyio_id);
                if (props.newUserCreated !== undefined) props.newUserCreated(email, user_uid, klavyio_id);
                setIsLoading(false);

            } else if (resp_createUser.data.user !== undefined) {
                let user_uid = resp_createUser.data.user.uid ?? "";
                console.log("addToFirebase | newUserCreated B", email, user_uid, klavyio_id);
                if (props.newUserCreated !== undefined) props.newUserCreated(email, user_uid, klavyio_id);
                setIsLoading(false);

            } else if (resp_createUser.data.code !== undefined && resp_createUser.data.code === 'auth/email-already-exists') {
                setIsLoading(false);
                setOkayToProceed(false);
                setErrorMessage("It looks like you've already signed up for Eigen Fitness Personal Training. Open the app to continue!");
                return;
            } else if (resp_createUser.data.code !== undefined && resp_createUser.data.code === 'auth/invalid-email') {
                setIsLoading(false);
                setOkayToProceed(false);
                setErrorMessage("Double check that you entered a valid email. If this issue persists, contact us at hey@eigenfitness.com to continue.");
                return;
            } else {
                console.log("Unknown response", resp_createUser)
            }
        })
        .catch((error2: any) => {
            console.log("Firebase error:", error2);
            setIsLoading(false);
        })
    }

    return (
        <div className="email page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>Where should we send your program?</h2>
                <p>
                    {
                        props.hasTrainer ? 
                        "Before we connect you to your Coach, let us know where to send your personalized training program.It will also be available in the Eigen Fitness app."
                        :
                        "Let us know where to send your personalized training program. You will be able to view the program in its entirety in the Eigen Fitness app"
                    }
                </p>
                
            </div>
            <div className="page-form-inner-input-container">
                <div className={`page-form-inner-input-text ${inputFocused ? 'page-form-inner-input-text-focused' : ''}`}>
                    <div className="page-form-inner-input-text-ion-wrapper">
                        <IonInput value={inputString} 
                            autofocus={true}
                            placeholder="My email address is..."
                            inputmode="text"
                            clearOnEdit={true}
                            type="email"
                            onKeyPress={e => inputUpdated(e.key)}
                            onIonChange={e => inputChanged(e.detail.value!, 'client-name')}
                            onFocus={(e) => setInputFocused(true)}
                            onBlur={(e) => setInputFocused(false)}/>
                    </div>
                    <div onClick={() => nextPagePressed()} className={`page-form-inner-input-text-button ${okayToProceed ? '' : 'page-form-inner-input-text-button-unselected'}`}>
                        {
                            isLoading ? 
                            <div className="page-form-inner-input-text-button-loading-wrapper">
                                <div className="email-loading-spinner"/>
                            </div>
                            :
                            <div className="page-form-inner-input-text-button-icon-wrapper">
                                <ArrowForward className="page-form-inner-input-text-button-icon"/>
                            </div>
                        }
                    </div>
                    
                </div>
                {
                    errorMessage !== '' ? 
                    <div className="email-error-container">
                        <p>{ errorMessage }</p>
                    </div>
                    : null
                }
            </div>
            <div className="page-form-inner-input-enter-icon-container">
                <div className={`page-form-inner-input-enter-icon ${inputFocused && okayToProceed ? '' :'page-form-inner-input-enter-icon-hide'}`}>
                    <p>Enter</p>
                </div>
            </div>
            
        </div>
    )
}

export default Email;