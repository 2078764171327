import React, { useState, useEffect } from 'react';
import './Gender.css';
import './Page-form-inner.css';
import GenderCard from '../components-form/GenderCard';
import '@material-ui/core';


function ActiveLevel(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [cardOptions, setCardOptions] = useState<{ title: string, id: any, active: boolean }[]>([
        {
            title: "Yes, I am",
            id: "yes",
            active: false
        },
        {
            title: "No, I'm not",
            id: "no",
            active: false
        },
        {
            title: "My life revolves around fitness",
            id: "revolves",
            active: false
        },
    ]);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() { };

    useEffect(() => { }, [cardOptions])

    function cardSelcted(withObj: { title: string, id: any, active: boolean }) {

        setCardOptions(cardOptions.map((item: any, index: number) => {
            if (item.id === withObj.id) {
                return { ...item, active: withObj.active }
            } else {
                return item;
            }
        }));

        props.setActiveLevel(withObj.id);

        nextButtonPressed();
    }

    function nextButtonPressed() {
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }



    return (
        <div className="weekdays page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>Your Activity Level</h2>
                <p>Are you currently phyically active?</p>
            </div>
            <div className="page-form-inner-input-container">
                <div className="gender-cards-wrapper">
                    {
                        cardOptions.map((item: { title: string, id: any, active: boolean }, index: number) => (
                            <GenderCard
                                key={item.id} // Added a unique key prop for each GenderCard
                                title={item.title}
                                id={item.id}
                                index={index}
                                isSelected={item.active}
                                cardSelected={(t: string, i: string, a: boolean) => cardSelcted({ title: t, id: i, active: a })} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ActiveLevel;