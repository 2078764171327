import React, { useState, useEffect } from 'react';
import './Injury.css';
import './Page-form-inner.css';

import InjuryCard from '../components-form/InjuryCard';

import '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

function Injury(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [cardOptions, setCardOptions] = useState<{title: string, id: any, active: boolean, img?: string}[]>([
        {
            title: "Shoulders",
            id: "shoulders",
            active: false,
            img: 'shoulder'
        },
        {
            title: "Elbows",
            id: "elbows",
            active: false,
            img: 'elbow'
        },
        {
            title: "Wrists",
            id: "wrists",
            active: false,
            img: 'wrist'
        },
        {
            title: "Lower Back",
            id: "low-back",
            active: false,
            img: 'back-lower'
        },
        {
            title: "Upper Back",
            id: "upper-back",
            active: false,
            img: 'back-upper'
        },
        {
            title: "Hips",
            id: "hips",
            active: false,
            img: 'hip'
        },
        {
            title: "Knees",
            id: "knees",
            active: false,
            img: 'knee'
        }
    ]);
    
    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {

        var activeList: string[] = [];
        for (var i = 0; i < cardOptions.length; i++) {
            if (cardOptions[i].active === true) {
                activeList.push(cardOptions[i].id);
            }
        }

        props.injuriesUpdated(activeList);

    }, [cardOptions])

    function cardSelcted(withObj: {title: string, id: any, active: boolean}) {
       
        setCardOptions(cardOptions.map((item: any, index: number) => {
            if (item.id === withObj.id) {
                return {...item, active: withObj.active}
            } else {
                return item;
            }
        }));
    }

    function nextButtonPressed() {
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }


	return (
		<div className="weekdays page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>Do you have any injuries that we should be aware of?</h2>
                <p>We will avoid exercises that place tension on these regions. Select all that apply.</p>
            </div>
            <div className="page-form-inner-input-container">
                <div className="experience-cards-wrapper">
                    {
                        cardOptions.map((item: {title: string, id: any, active: boolean, img?: string}, index: number) => (
                            <InjuryCard 
                                title={item.title}
                                id={item.id}
                                index={index}
                                isSelected={item.active}
                                imgName={item.img === undefined ? '' : item.img}
                                cardSelected={(t: string, i: string, a: boolean) => cardSelcted({title: t, id: i, active: a})}/>
                        ))
                    }
                </div>
            </div>
            <div className="page-form-inner-confirm-button-container">
                <div onClick={() => nextButtonPressed()} className="page-form-inner-confirm-button">
                    <p>Weekdays</p>
                    <ArrowForward className="page-form-inner-confirm-button-icon"/>
                </div>
            </div>
        </div>
	)
}

export default Injury;