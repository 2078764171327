import React, { useState, useEffect } from 'react';
import './Gender.css';
import './Page-form-inner.css';
import GenderCard from '../components-form/GenderCard';
import '@material-ui/core';

function UniqueNeeds(props: any) {
    const [initSet, setInitSet] = useState(false);
    const [cardOptions, setCardOptions] = useState<{
        title: string;
        id: any;
        active: boolean;
    }[]>([
        {
            title: 'I have a severe injury',
            id: 'severeInjury',
            active: false,
        },
        {
            title: 'I am currently pregnant',
            id: 'pregnant',
            active: false,
        },
        {
            title: 'I have had a baby in the last year',
            id: 'babyLastYear',
            active: false,
        },
        {
            title: 'I have a health condition',
            id: 'healthCondition',
            active: false,
        },
        {
            title: 'I have a Physical Disability',
            id: 'physicalDisability',
            active: false,
        },
        {
            title: 'I travel Frequently',
            id: 'travelFrequently',
            active: false,
        },
        {
            title: 'None of the above',
            id: 'none',
            active: false,
        },
    ]);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() { }

    useEffect(() => { }, [cardOptions]);

    function cardSelected(withObj: { title: string; id: any; active: boolean }) {
        setCardOptions(
            cardOptions.map((item: any, index: number) => {
                if (item.id === withObj.id) {
                    return { ...item, active: withObj.active };
                } else {
                    return item;
                }
            })
        );

        props.setUniqueNeeds(withObj.id);

        nextButtonPressed();
    }

    function nextButtonPressed() {
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

    return (
        <div className="weekdays page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>Do you have any unique needs?</h2>
                <p>It is important that your Coach has experience with any unique needs you may have</p>
            </div>
            <div className="page-form-inner-input-container">
                <div className="gender-cards-wrapper">
                    {cardOptions.map(
                        (item: { title: string; id: any; active: boolean }, index: number) => (
                            <GenderCard
                                key={item.id}
                                title={item.title}
                                id={item.id}
                                index={index}
                                isSelected={item.active}
                                cardSelected={(t: string, i: string, a: boolean) =>
                                    cardSelected({ title: t, id: i, active: a })
                                }
                            />
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default UniqueNeeds;
