import React, { useState, useEffect } from 'react';
import './GenderCard.css';


import '@material-ui/core';
import Check from '@material-ui/icons/Check';

function GenderCard(props: any) {

    const [initSet, setInitSet] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {

    }

    useEffect(() => {
        console.log("props.isSelected", props.isSelected);
        if (props.isSelected !== undefined) {
            setIsSelected(props.isSelected);
        }
    }, [props.isSelected])

    function cardSelcted() {
        if (props.cardSelected !== undefined) {
            props.cardSelected(props.title, props.id, !isSelected);
        }
    }

    return (
        <div
            onClick={() => cardSelcted()}
            className={`gender-card ${isSelected ? 'gender-card-selected' : ''}`}
        >
            <div hidden={!isSelected} className="gender-card-check-container">
                <Check className="gender-card-check" />
            </div>
            <div className="gender-card-inner">
                <div className="gender-card-title-container">
                    <h3>{props.title === undefined ? 'Undefined card' : props.title}</h3>
                </div>
            </div>
        </div>
    )
}

export default GenderCard;