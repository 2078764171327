import React, { useState, useEffect } from 'react';
import './Improving.css';
import './Page-form-inner.css';

import ImprovingCard from '../components-form/ImprovingCard';


function Improving(props: any) {

    const cardOptions: {title: string, id: any, img?: string}[] = [
        {
            title: "I want to reduce body fat",
            id: "decrease-body-fat",
            img: 'loose-body-fat'
        },
        {
            title: "I want to gain muscle mass",
            id: "increase-muscle-mass",
            img: 'bodybuilding'
        },
        {
            title: "I want to increase my strength",
            id: "increase-strength",
            img: 'powerlifting'
        },
        {
            title: "I want to improve my cardio endurance",
            id: "increase-cardio",
            img: 'running'
        },
        {
            title: "Just maintain my current state",
            id: "maintain",
            img: 'wellness'
        }
    ]

    const [initSet, setInitSet] = useState(false);
    const [selectedCardOption, setSelectedCardOption] = useState<{title: string, id: any} | undefined>(undefined);

    if (initSet === false) {
        setInitSet(true);
        initialize();
    }

    function initialize() {
        
    }

    useEffect(() => {
        if (selectedCardOption !== undefined) {
            props.goalUpdated(selectedCardOption.id);
        }
    }, [selectedCardOption])

    function cardSelcted(withObj: {title: string, id: any}) {
        setSelectedCardOption(withObj);
        console.log("cardSelcted", withObj)
        if (props.nextPagePressed !== undefined) {
            props.nextPagePressed();
        }
    }

	return (
		<div className="improvement page-form-inner">
            <div className="page-form-inner-question-container">
                <h2>What do you want to improve on the most?</h2>
            </div>
            <div className="page-form-inner-input-container">
                <div className="experience-cards-wrapper">
                    {
                        cardOptions.map((item: {title: string, id: any, img?: string}, index: number) => (
                            <ImprovingCard 
                                title={item.title}
                                id={item.id}
                                index={index}
                                imgName={item.img}
                                isSelected={selectedCardOption !== undefined && selectedCardOption.id === item.id}
                                cardSelected={(t: string, i: string) => cardSelcted({title: t, id: i})}/>
                        ))
                    }
                </div>
            </div>
        </div>
	)
}

export default Improving;